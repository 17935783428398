import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class NewsStore extends LocalModel {
    constructor() {
        super('news', {
            namePlural: 'newsList',
            namePluralReal: 'news',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/news/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/news/',
                    params: {},
                },
                save: {
                    url: '/api/news/',
                    params: {},
                },
                delete: {
                    url: '/api/news/',
                    params: {},
                },
            },
        });
    }

    @observable newNews = {};

    @observable news = {};

    @observable newsList = [];

    @observable newsListAlt = [];

    @observable races = [];

}

const store = new NewsStore();
export default store;
