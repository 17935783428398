import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

function sortByName(a, b) {
    if (a[1] < b[1]) {
        return -1;
    }
    if (a[1] > b[1]) {
        return 1;
    }
    return 0;
}

@withText(props => ({
    fileNumber: <Text id='input.fileNumber-help'>fileNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class NewFile extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('file'),
            mapToFields: [],
        };
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id } = props;
        const { fileStore } = this.props.stores;
        if (emptyFirst) {
            fileStore.updateKeyValue('file', {});
        }
        await fileStore.load(id, false, { addData: ['tags', 'animalFields', 'visitorFields', 'userFields', 'customerFields'], query: {} });

        const { animalFields, visitorFields, userFields, customerFields } = fileStore;

        const mapToFields = [
            // ['animal.name', 'Animal name'],
            // ['animal.birth', 'Animal birth'],
        ];

        animalFields.forEach(field => {
            mapToFields.push([`animal.${field}`, `Animal ${field}`]);
        });

        visitorFields.forEach(field => {
            mapToFields.push([`visitor.${field}`, `Visitor ${field}`]);
        });

        userFields.forEach(field => {
            mapToFields.push([`user.${field}`, `User ${field}`]);
        });

        customerFields.forEach(field => {
            mapToFields.push([`customer.${field}`, `Customer ${field}`]);
        });

        this.setState({
            mapToFields,
        });
    }

    saveMapTo = (e) => {
        const { name, value } = e.target;
        const { md5 } = e.target.dataset;
        console.log('saveMapTo', { md5, name, value });

        const { fileStore } = this.props.stores;
        const { file } = fileStore;
        const { files } = file;

        const currentFile = files.find(f => f.md5 === md5);
        const currentFileIndex = files.findIndex(f => f.md5 === md5);
        const pdfFields = JSON.parse(JSON.stringify(currentFile.pdfFields))
console.log('pdfFields', pdfFields);

        const fieldIndex = pdfFields.findIndex(f => f[1] === name);
        pdfFields[fieldIndex][2] = value;
console.log('pdfFields', pdfFields);

console.log('updateObjectKeyArrFieldValue', 'file', 'files', currentFileIndex, 'pdfFields', pdfFields);
        fileStore.updateObjectKeyArrFieldValue('file', 'files', currentFileIndex, 'pdfFields', pdfFields);
        fileStore.editArrayObject({
            id: file.id,
            field: 'files',
            key: 'pdfFields',
            value: pdfFields,
            md5,
            objectName: 'file',
        })

        // {
        //     name: 'testfile',
        //     files: [
        //         {
        //             md5: '1234',
        //             pdfFields: [
        //                 ['PDFTextField', 'name', 'animal.name'],
        //                 ['PDFTextField', 'birth', 'animal.birth'],
        //             ]
        //         },
        //     ]
        // }


        // const { fileStore } = this.props.stores;
        // const { file } = fileStore;
        // const { pdfFields } = file;
        // const [type, name, mapTo] = pdfFields;

        // pdfFields[2] = value;
        // fileStore.updateKeyValue('file', {
        //     ...file,
        //     pdfFields,
        // });
    }

    createFile = async () => {
        const { callback = () => {} } = this.props;
        const { fileStore } = this.props.stores;
        const { newFile } = fileStore;

        const response = await fileStore.insert(newFile);

        fileStore.updateKeyValue('newFile', {});
        callback();
    }

    chooseItem = (e) => {
        const { id } = e.target.closest('.similar-item').dataset;
        const { fileStore } = this.props.stores;
        const { foundList } = fileStore;
        const item = foundList.find((item) => item.id === parseInt(id, 10));
        fileStore.updateKeyValue('newFile', {
            ...item,
        });
        fileStore.resetSearch();
    }

    toggleSimilar = () => {
        const { showSimilar } = this.state;
        this.setState({
            showSimilar: !showSimilar,
        });
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { week, isNew, drawerLevel, installation, lookupArray } = this.props;
        const { sections, showSimilar, mapToFields } = this.state;
        const { userStore, fileStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { newFile, foundList, foundListKeys, file } = fileStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const apiServer = util.getApiServer();
        const jwtToken = util.getJwtToken();

        return (<>
            <div class='w-100 d-flex justify-content-center position-relative'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    {/* <xmp>{JSON.stringify(installation, null, 4)}</xmp> */}
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        {isNew ? <Text id='file.new'>New file</Text> : <Text id='file.edit'>Edit file</Text>}
                    </div>

                    <div class='w-100 d-flex flex-column justify-content-start'>
                        <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                            <FormFields
                                stores={this.props.stores}
                                sections={sections}
                                holdingStore={fileStore}
                                objectName={isNew ? 'newFile' : 'file'}
                                isNew={isNew}
                                drawerLevel={drawerLevel}
                                isAdmin={false}
                                hideAdmin={true}
                                lookupArray={lookupArray}
                                rightHeader={isNew && foundList?.length > 0 ? <span class='text-success font-weight-bold'><i class='fa-solid fa-circle-info' /> Similar files ({foundList.length})</span> : null}
                                rightHeaderClick={this.toggleSimilar}
                            />
                        </div>
                    </div>

                    {file?.files && file?.files.length > 0 && <>
                        <div class='table-responsive'>
                            <table class='table table-striped table-hover table-sm'>
                                <thead>
                                    <tr>
                                        <th scope='col'>#</th>
                                        <th scope='col'><Text id='file.type'>Type</Text></th>
                                        <th scope='col'><Text id='file.description'>Description</Text></th>
                                        <th scope='col'><Text id='file.map-to'>Map to field</Text></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {file.files.map((f, index) => {
                                        const pdfFields = f.pdfFields;
                                        return (<>
                                            <tr>
                                                <th colspan='4'>
                                                    <a
                                                        class='btn btn-sm btn-link ml-2 p-0 m-0 text-left'
                                                        href={`${apiServer}/api/files/${file.id}/download?jwtToken=${jwtToken}&md5=${f.md5}&showFieldNames=1`}
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                    >
                                                        <i class={`fa-duotone ${localUtil.resolveFontawesomeFiletype(f.ext)}`} /> {f.name}
                                                    </a>
                                                </th>
                                            </tr>
                                            {/* <xmp>{JSON.stringify(file, null, 4)}</xmp> */}
                                            {pdfFields && pdfFields.map((pdfField, index) => {
                                                const [type, name, mapTo] = pdfField;
                                                if (type === 'PDFTextField') {
                                                    return (<>
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{type}</td>
                                                            <td>{name}</td>
                                                            <td>
                                                                <select
                                                                    class='form-select'
                                                                    value={mapTo}
                                                                    name={name}
                                                                    onInput={this.saveMapTo}
                                                                    data-md5={f.md5}
                                                                >
                                                                    <option value=''>-- Select field --</option>
                                                                    {mapToFields.sort(sortByName).map(field => {
                                                                        const [key, name] = field;
                                                                        return (<option value={key}>{name}</option>);
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </>);
                                                }
                                            })}
                                        </>);
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>}


                </div>
            </div>

            {isNew && <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                <button type='button' class='btn btn-primary rounded-pill' onClick={this.createFile}>
                    {isNew ? <Text id='file.create'>Create file</Text> : <Text id='file.save'>Save file</Text>}
                </button>
            </div>}
        </>);
    }
}

export default NewFile;
