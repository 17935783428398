import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    partNumber: <Text id='input.partNumber-help'>partNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class NewPart extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('part'),
        };
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id, installationId } = props;
        const { partStore } = this.props.stores;
        if (emptyFirst) {
            partStore.updateKeyValue('part', {});
        }
        if (id) {
            await partStore.load(id, false, { addData: ['installations'], query: { installationId } });
        }
    }

    createPart = async () => {
        const { callback = () => {}, installation } = this.props;
        const { partStore, installationStore } = this.props.stores;
        const { newPart } = partStore;

        if (!newPart.partNumber) {
            partStore.updateObjectKeyValue('newPart', 'partNumber', newPart.name);
        }
        // Check if part exists before creating it:
        const existingPart = await partStore.load({
            query: { partNumber: newPart.partNumber },
            skipUpdate: true,
        });

        let part = {};
        if (existingPart?.length > 0) {
            part = existingPart[0];
            const response = await partStore.save({
                id: part.id,
                ...newPart,
            });
        } else {
            const response = await partStore.insert(newPart);
            part = response?.data?.part;
        }
        if (installation?.id) {
            const newObject = {
                qty: 1,
                id: part.id,
                name: part.name,
                partNumber: part.partNumber,
            };
            await installationStore.addArrayObject({
                newObject,
                object: installation,
                field: 'parts',
                // objectName: 'installation',
            });
        }
        partStore.updateKeyValue('newPart', {});
        callback();
    }

    chooseItem = (e) => {
        const { id } = e.target.closest('.similar-item').dataset;
        const { partStore } = this.props.stores;
        const { foundList } = partStore;
        const item = foundList.find((item) => item.id === parseInt(id, 10));
        partStore.updateKeyValue('newPart', {
            ...item,
        });
        partStore.resetSearch();
    }

    toggleSimilar = () => {
        const { showSimilar } = this.state;
        this.setState({
            showSimilar: !showSimilar,
        });
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { week, isNew, drawerLevel, installation, lookupArray = 'parts' } = this.props;
        const { sections, showSimilar } = this.state;
        const { userStore, partStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { newPart, foundList, foundListKeys } = partStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center position-relative'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    {/* <xmp>{JSON.stringify(installation, null, 4)}</xmp> */}
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        {isNew ? <Text id='part.new'>New part</Text> : <Text id='part.edit'>Edit part</Text>}
                        {installation?.id && <>
                            <span class='ml-2'>
                                :: {installation.name}
                            </span>
                        </>}
                    </div>

                    {showSimilar && foundList?.length > 0 && <>
                        <div class='w-100 d-flex flex-column justify-content-start'>
                            <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                                <div class='mx-4 font-weight-lighter px-3 py-1 text-muted' >
                                    <small class='text-uppercase'>
                                        <Text id='part.similar'>Similar parts</Text>
                                    </small>
                                </div>
                                <div class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-4 d-flex flex-column px-0 overflow-auto`} style='font-size: 1.1em; border-radius: 15px; max-height: 35vh;'>
                                    {foundList.map((item, idx) => {
                                        return(<>
                                            <div class='d-flex flex-row justify-content-between align-items-center'>
                                                <div
                                                    class='flex-fill similar-item py-2 px-3'
                                                    data-id={item.id}
                                                    onClick={this.chooseItem}
                                                >
                                                    {item.name}
                                                    <span class='font-weight-lighter text-muted ml-3'>{item.partNumber}</span>
                                                </div>
                                                {/* {item.images[0] && <>
                                                    <div>
                                                        <div class=''>
                                                            <img src={`${item.images[0].s3MediumLink}`} class='rounded-lg img-fluid' style='max-height: 45px;' />
                                                        </div>
                                                    </div>
                                                </>} */}
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </div>
                        </div>
                    </>}

                    <div class='w-100 d-flex flex-column justify-content-start'>
                        <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                            <FormFields
                                stores={this.props.stores}
                                sections={sections}
                                holdingStore={partStore}
                                objectName={isNew ? 'newPart' : 'part'}
                                isNew={isNew}
                                drawerLevel={drawerLevel}
                                isAdmin={false}
                                lookupArray={lookupArray}
                                rightHeader={isNew && foundList?.length > 0 ? <span class='text-success font-weight-bold'><i class='fa-solid fa-circle-info' /> Similar parts ({foundList.length})</span> : null}
                                rightHeaderClick={this.toggleSimilar}
                            />
                        </div>
                    </div>
                </div>



            </div>

            {isNew && <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                <button type='button' class='btn btn-primary rounded-pill' onClick={this.createPart}>
                    {isNew ? <Text id='part.create'>Create part</Text> : <Text id='part.save'>Save part</Text>}
                </button>
            </div>}
        </>);
    }
}

export default NewPart;
