import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FastList from '../../components/fastlist';
import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import FileList from '../../components/fileViewer/list';
import GradientRight from '../../components/gui/gradientRight';
import Placeholder from '../../components/gui/placeholder';

import VideoStreamingPlayer from '../../routes/videostreaming/player';

const scrollDistance = 210;
const scrollDistanceSnap = 90;
const activationRange = 20;

const fieldSorter = (fields) => (a, b) => fields.map(o => {
    let dir = 1;
    if (o[0] === '-') {
        dir = -1;
        o = o.substring(1);
    }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
}).reduce((p, n) => p ? p : n, 0);

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
}))
@observer
class GuiList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            focusedIndex: 0,
        };
    }

    viewElement = (e, props) => {
        e.stopPropagation();
        e.preventDefault();
        const { swipeIdOpen } = this.state;
        const { id } = props ? props?.id : e?.target.closest('.box-line').dataset;
        const objId = parseInt(id, 10);
        if (swipeIdOpen === objId) {
            this.closeSwipMenu(e);
            return;
        }
        const { view = () => {} } = this.props;
        view(e, props);
    }

    deleteElement = e => {
        const { deleteElement = () => {} } = this.props;
        deleteElement(e);
        this.closeSwipMenu(e);
    }

    rentOutElement = e => {
        const { rentOutElement = () => {} } = this.props;
        rentOutElement(e);
        this.closeSwipMenu(e);
    }

    moveElement = e => {
        const { moveElement = () => {} } = this.props;
        moveElement(e);
        this.closeSwipMenu(e);
    }

    addUsageData = e => {
        const { addUsageData = () => {} } = this.props;
        addUsageData(e);
        this.closeSwipMenu(e);
    }

    checkElement = e => {
        const { checkElement = () => {} } = this.props;
        checkElement(e);
        this.closeSwipMenu(e);
    }

    editElement = e => {
        const { editElement = () => {} } = this.props;
        editElement(e);
        this.closeSwipMenu(e);
    }

    refreshElement = async e => {
        const { refreshElement = () => {} } = this.props;
        this.setState({ refreshElementInProgress: true });
        await refreshElement(e);
        this.setState({ refreshElementInProgress: false });
        this.closeSwipMenu(e);
    }

    handleIntersection = (entries) => {
        const { holdingStore } = this.props;
        if (holdingStore) {
            holdingStore.intersectionObserverCallback(entries);
        }
    }

    // Swipe shit
    touchStart = (e) => {
        const { id } = e.target.closest('.box-line').dataset;
        const { isSwipedOpen } = this.state;
        this.setState({
            swipeId: parseInt(id, 10),
            startY: e.touches[0].pageY,
            startX: e.touches[0].pageX,
            isSwipedOpen: false,
            diffY: undefined,
            diffX: undefined,
            // absDiffX: undefined,
            deleteVerify: false,
        });
    }

    touchEnd = (e) => {
        const { isSwipedOpen, absDiffX } = this.state;
        if (isSwipedOpen) {
            this.setState({
                startY: undefined,
                startX: undefined,
            });
        } else if (absDiffX >= scrollDistanceSnap) {
            const { id } = e.target.closest('.box-line').dataset;
            const swipeId = parseInt(id, 10);
            this.setState({
                isSwipedOpen: true,
                swipeIdOpen: swipeId,
                absDiffX: scrollDistance,
            });
        } else if (absDiffX >= activationRange) {
            this.setState({
                startY: undefined,
                startX: undefined,
                isSwipedOpen: false,
                deleteVerify: false,
                diffY: 1,
                diffX: 1,
                absDiffX: 1,
            });
            setTimeout(() => {
                this.setState({
                    swipeIdOpen: undefined,
                    diffY: undefined,
                    diffX: undefined,
                    absDiffX: undefined,
                });
            }, 400);
        }
    }

    touchMove = (e) => {
        // e.stopPropagation(); // This should be removed to allow pull to refresh when on top of page.
        const { isSwipedOpen } = this.state;
        if (isSwipedOpen) {
            return false;
        }
        const { startY, startX } = this.state;
        const y = e.touches[0].pageY;
        const x = e.touches[0].pageX;
        const diffY = y - startY;
        const absDiffY = Math.abs(diffY);
        let diffX = x - startX;
        let absDiffX = Math.abs(diffX);
        let swipeDirection = '';

        if ( absDiffX < absDiffY ) { /*most significant*/
            return false;
        }

        if (absDiffX < activationRange) {
            return true;
        } else {
            e.preventDefault();
            e.stopPropagation();
        }
        if (diffX > 0) {
            swipeDirection = 'right';
        } else if (diffX < 0) {
            swipeDirection = 'left';
        }
        if (absDiffX >= scrollDistance) {
            absDiffX = scrollDistance;
        }
        // console.log({ absDiffX, scrollDistance, swipeDirection });
        this.setState({
            diffY,
            diffX,
            absDiffX,
            swipeDirection,
        });

        if (y > (startY + scrollDistance)) {
            // console.log('swiping down', y, '>', startY + scrollDistance);
        } else if (y < (startY - scrollDistance)) {
            // console.log('swiping up', y, '<', startY + scrollDistance);
        }

        if (absDiffX >= scrollDistance) {
            const { id } = e.target.closest('.box-line').dataset;
            const swipeId = parseInt(id, 10);
            this.setState({
                isSwipedOpen: true,
                swipeIdOpen: swipeId,
            });
        }
    }

    closeSwipMenu = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            diffY: 1,
            diffX: 1,
            absDiffX: 1,
        });
        setTimeout(() => {
            this.setState({
                isSwipedOpen: false,
                swipeIdOpen: undefined,
                diffY: undefined,
                diffX: undefined,
                absDiffX: undefined,
                deleteVerify: false,
            });
        }, 400);
    }
    // /Swipe shit

    clearSearch = () => {
        this.setState({
            search: '',
        });
    }

    searchInput = (e) => {
        const search = e.target.value;
        const { holdingStore, searchCallback = () => {} } = this.props;
        this.setState({ search });
        searchCallback(search);
    }

    componentDidMount() {
        // this.setLoadMore();
        const { holdingStore } = this.props;
        if (holdingStore) {
            this.scrollViewTimer = setInterval(() => holdingStore.postScrollview(), 30 * 1000);
        }
    }
    componentWillUnmount() {
        // this.unsetLoadMore();
        const { holdingStore } = this.props;
        if (holdingStore) {
            holdingStore.postScrollview()
            clearInterval(this.scrollViewTimer);
        }
    }

    onClickMenu = e => {
        const { showMenu } = this.props;
        if (showMenu) {
            showMenu(e);
        }
    }

    handleKeyDown = (e) => {
        const { search, focusedIndex } = this.state;
        const {
            list = [],
            sortBy = [],
            sortTitle = '',
        } = this.props;

        let finalList = list.filter(el => {
            if (!search) {
                return true;
            }
            const searchLower = search.toLowerCase();
            const name = el.name?.toLowerCase() || el.client?.toLowerCase() || '';
            const description = el.description?.toLowerCase() || '';
            const tags = el.tags ? el.tags.join(' ').toLowerCase() : [];
            return name.includes(searchLower) || description.includes(searchLower) || tags.includes(searchLower);
        });
        const listLength = finalList.length;

        if (sortBy.length > 0) {
            finalList = finalList.sort(fieldSorter([sortTitle]));
        }

        if (e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = (focusedIndex + 1) % listLength;
            this.setState({
                focusStarted: true,
                focusedIndex: nextIndex,
            });
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            const prevIndex = (focusedIndex - 1 + listLength) % listLength;
            this.setState({
                focusStarted: true,
                focusedIndex: prevIndex,
             });
        } else if (e.key === 'Enter') {
            e.preventDefault();
            const obj = finalList[focusedIndex];
            const viewElement = obj.viewElement || this.viewElement;
            viewElement(null, {
                id: obj.id,
            });
        }
    }

    showImage({ obj, maxImageWidth, maxWidth, maxHeight, imageContainerStyles, imageStyles, hasVideo, imageLocation, currentListContainerClassname }) {
        const { elLink } = this.props;
        if (imageLocation === 'right' || imageLocation === 'left' || currentListContainerClassname !== 'w-100') {
            return (<>
                {obj.images[0] ? <>
                    <div
                        class='d-flex'
                        style={`${maxImageWidth ? `width: ${maxImageWidth}; max-width: ${maxImageWidth}; min-width: ${maxImageWidth};` : ''}`}
                    >
                        <div class='w-100 h-100'>
                            <div class='d-flex flex-row flex-nowrap h-100'>
                                <div
                                    class='w-100 h-100 text-center imageContainer d-flex justify-content-center align-items-center position-relative overflow-hidden'
                                    style='max-height: 40vh; min-height: 100px;'
                                >
                                    {elLink ? <>
                                        <a href={elLink(obj)} class='text-dark'>
                                            <img
                                                src={`${obj.images[0].s3LargeLink}`}
                                                class='img-fluid'
                                                style={`
                                                    object-fit: cover;
                                                    aspect-ratio: 4 / 3;
                                                `}
                                            />
                                        </a>
                                    </> : <>
                                        <img
                                            src={`${obj.images[0].s3LargeLink}`}
                                            class='img-fluid'
                                            style={`
                                                object-fit: cover;
                                                aspect-ratio: 4 / 3;
                                            `}
                                        />
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </> : <></>}
            </>)
        }

        return (<>
            <div class='d-flex' style={`${maxImageWidth ? `width: ${maxImageWidth}; max-width: ${maxImageWidth}; min-width: ${maxImageWidth};` : ''}`}>
                {hasVideo ? <>
                    {obj.files?.length > 0 && obj.files.map(file => {
                        const poster = file.s3LinkStreamPreview1;
                        // console.log({ file });
                        if (file.s3LinkStream) {
                            return (<>
                                <div class='video-container'>
                                    <VideoStreamingPlayer
                                        key={file.s3LinkStream}
                                        videoStreamUrl={file.s3LinkStream}
                                        stores={this.props.stores}
                                        poster={poster}
                                    />
                                </div>
                            </>);
                        }
                        if (file.s3Link && file.s3Link.match(/\.(m4v|m4a|mp4)$/i)) {
                            return (<>
                                <div className='video-container'>
                                    <video controls className='video-player'>
                                        <source src={file.s3Link} type='video/mp4' />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </>);
                        }
                        if (file.s3Link && file.s3Link.match(/\.(mov)$/i)) {
                            return (<>
                                <div className='video-container'>
                                    <video controls className='video-player'>
                                        <source src={file.s3Link} type='video/quicktime' />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </>);
                        }
                    })}
                </> : <>
                    <ImageScroller
                        images={obj.images}
                        stores={this.props.stores}
                        showImg={img => localUtil.displayImg(img, this.props, '1024x')}
                        showImageInfo={false}
                        maxHeight={maxHeight}
                        maxWidth={maxWidth}
                        rounded={false}
                        imageContainerStyles={imageContainerStyles}
                        imageStyles={imageStyles}
                        classNames='h-100'
                        classNamesWrapper1='h-100'
                    />
                </>}
            </div>
        </>);
    }

    renderContent = ({ obj, idx }) => {
        const {
            title = '',
            list = [],
            classNames = '',
            qty,
            elHeader,
            elTitle = e => e.title,
            elLink,
            elImageStatus = e => '',
            elDescription = e => '',
            elInfo1 = e => '',
            elInfo2 = e => '',
            elInfo3 = e => '',
            viewTags,
            inc,
            dec,
            remove,
            editQty,
            wrapperProps = {},
            deleteElement,
            rentOutElement,
            moveElement,
            checkElement,
            addUsageData,
            editElement,
            refreshElement,
            showCheckElement = () => true,
            showIncDec = () => true,
            showEmptyImage = true,
            showCevron = false,
            showRightIcon,
            rightBoxField = '',
            rightBoxFieldValue = e => e,
            rightBoxFieldPostfix = '',
            rightBoxFieldPostfixField,
            rightBoxFieldPostfixValue,
            rightBoxFieldBottomValue,

            rightBoxTitle,
            rightBoxValue = () => '',
            rightBoxClass = () => '',
            maxHeight = '25vh',
            maxWidth,
            imageContainerStyles = '',
            imageStyles = '',

            listClasses = [],
            listStyles = [],
            listTitleStyles = [],
            listContainerClassnames = [],
            listWrapperClassnames = [],
            listMaxImageWidth = [],
            listImageLocations = [],

            imgHeight,
            showMenu,
            holdingStore,
        } = this.props;
        const { appState, userStore } = this.props.stores;
        const { opts = {} } = appState;

        const { absDiffX, swipeId, swipeDirection, refreshElementInProgress, focusedIndex, focusStarted } = this.state;
        const swipeMenuIsOpen = absDiffX > 0;

        const hasSwipeLeftFunctions = (deleteElement || rentOutElement || moveElement || checkElement || addUsageData);
        const hasSwipeRightFunctions = (editElement);
        const hasSwipeFunctions = (hasSwipeLeftFunctions || hasSwipeRightFunctions);

        let isElementSwipedOpen = false;
        if (swipeDirection === 'left' && opts.swipeLeft) {
            isElementSwipedOpen = (
                swipeId === obj.id &&
                swipeMenuIsOpen &&
                hasSwipeLeftFunctions
            );
        } else if (swipeDirection === 'right' && opts.swipeRight) {
            isElementSwipedOpen = (
                swipeId === obj.id &&
                swipeMenuIsOpen &&
                hasSwipeRightFunctions
            );
        }

        const { user } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const hasVideo = obj.files?.some(file => file.s3LinkStream || file.s3Link.match(/\.(m4v|m4a|mp4|mov)$/i));

        const currentListContainerClassname = listContainerClassnames[idx];
        const currentListWrapperClassname = listWrapperClassnames[idx] || 'rounded-lg';
        const currentListClasses = listClasses[idx] || 'flex-column justify-content-between';
        const currentListStyles = listStyles[idx] || '';
        const currentListTitleStyles = listTitleStyles[idx] || '';
        const maxImageWidth = listMaxImageWidth[idx] || '';
        const imageLocation = listImageLocations[idx] || 'left';

        return (<>
            <div class={`w-100 d-flex flex-row position-relative mb-3 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} ${currentListWrapperClassname} overflow-hidden`}>
                <div
                    class={`w-100 flex-fill d-flex ${currentListClasses} box-line overflow-hidden position-relative ${obj.classNames || ''} ${(focusStarted && focusedIndex === idx) ? 'box-line-focused' : ''}`}
                    onClick={obj.viewElement || this.viewElement}
                    data-isopen={swipeMenuIsOpen}
                    data-id={obj.id}
                    data-title={elTitle(obj)}
                    data-type={holdingStore?.name}
                    {...wrapperProps}
                    onTouchstart={hasSwipeFunctions && this.touchStart}
                    onTouchend={hasSwipeFunctions && this.touchEnd}
                    onTouchmove={hasSwipeFunctions && this.touchMove}
                    style={`
                        ${obj.styles || ''}
                        ${isElementSwipedOpen ? `
                            overflow: hidden;
                            left: ${swipeDirection === 'left' ? `-` : ''}${absDiffX || 0}px !important;
                        ` : ''}
                        ${currentListStyles}
                    `}
                >

                    {(imageLocation === 'top') && <>
                        {elHeader && <div class='d-flex flex-row w-100'>
                            {elHeader(obj)}
                        </div>}
                    </>}
                    {(imageLocation === 'left' || imageLocation === 'top') && <>
                        {this.showImage({ obj, maxImageWidth, maxWidth, maxHeight, imageContainerStyles, imageStyles, hasVideo, imageLocation, currentListContainerClassname })}
                    </>}

                    {/* swipeIdOpen: {swipeIdOpen} */}
                    {/* swipeId: {swipeId} */}
                    {/* diffX: {diffX} */}
                    {/* isSwipedOpen: {JSON.stringify(isSwipedOpen, null, 4)} */}
                    {/* isSwipedOpen: {JSON.stringify({ diffX, isSwipedOpen, swipeId}, null, 4)} */}
                    {qty ? <>
                        <div class='box-line-qty text-right'>{qty(obj)}</div>
                    </> : <>
                        {util.isDefined(obj.qty) && <div
                            class='box-line-qty text-right'
                            onClick={opts.allowEditQty ? editQty : () => {}}
                            data-id={obj.id}
                            data-qty={obj.qty}
                        >
                            {obj.qty}
                        </div>}
                    </>}
                    {obj.icon && <>
                        <div class='box-line-icon mt-2 ml-3' style='font-size: 1.7em; width: 25px;'>
                            <i class={obj.icon} />
                        </div>
                    </>}
                    <div class='flex-fill d-flex flex-column pt-2 overflow-hidden'>
                        {(imageLocation !== 'top') && <>
                            {elHeader && <div class='d-flex flex-row w-100'>
                                {elHeader(obj)}
                            </div>}
                        </>}
                        <div
                            class='w-100 d-flex flex-row justify-content-between align-items-center pl-3 pr-2 position-relative'
                        >
                            {/* <GradientRight stores={this.props.stores} /> */}
                            <div class='box-line-content w-100'>
                                <div
                                    class='box-line-title px-0 overflow-hidden'
                                    style={`
                                        white-space: normal !important;
                                        ${currentListTitleStyles}
                                        text-overflow: ellipsis;
                                    `}
                                >
                                    {elLink ? <>
                                        <a href={elLink(obj)} class='text-dark'>
                                            {elTitle(obj)}
                                        </a>
                                    </> : <>
                                        {elTitle(obj)}
                                    </>}
                                </div>
                                <div class='box-line-body px-0'>
                                    {elDescription(obj) && <div>
                                        {elDescription(obj)}
                                    </div>}
                                </div>
                            </div>
                            {showMenu && <div
                                class='position-absolute'
                                style='top: 10px; right: 10px; z-index: 100;'
                            >
                                <button
                                    type='button'
                                    class='btn btn-lg btn-link py-0 list-menu'
                                    onClick={this.onClickMenu}
                                    data-id={obj.id}
                                >
                                    <i class='fa-solid fa-ellipsis-vertical text-secondary' />
                                </button>
                            </div>}
                        </div>
                        <div class='box-line-info d-flex flex-row justify-content-between px-3 pb-0 position-relative'>
                            {/* <GradientRight stores={this.props.stores} /> */}
                            <div class='box-line-info-item'>
                                {elInfo1(obj)}
                            </div>
                            <div class='box-line-info-item'>
                                {elInfo2(obj)}
                            </div>
                            <div class='box-line-info-item'>
                                {elInfo3(obj)}
                            </div>
                            {/* <div class='box-line-info-item'>
                                <FileList files={obj.files} stores={this.props.stores} />
                            </div> */}
                            {/* <div class='box-line-info-item'>
                                <span class='mr-2'>
                                    <i class='fa-solid fa-magnifying-glass' />
                                </span>
                                {obj.inspections.length}
                            </div> */}
                        </div>
                        <div class='box-line-info d-flex flex-row justify-content-between px-3 pb-0 mb-2 position-relative'>
                            {/* <GradientRight stores={this.props.stores} /> */}
                            {viewTags && <>
                                {viewTags(obj)}
                            </>}
                        </div>
                    </div>

                    {(imageLocation === 'right') && <>
                        {this.showImage({ obj, maxImageWidth, maxWidth, maxHeight, imageContainerStyles, imageStyles, hasVideo, imageLocation, currentListContainerClassname })}
                    </>}

                    {obj[rightBoxField] && rightBoxFieldValue(obj[rightBoxField]) && <>
                        <div
                            class='box-line-right-box mt-1 mb-1 mr-2 py-1 text-muted bg-light rounded-lg d-flex flex-column justify-content-center align-items-center flex-wrap text-center'
                            style='width: 60px !important; min-width: 60px !important; line-height: 1.0em;'
                        >
                            <span class='font-weight-bold'>{rightBoxFieldValue(obj[rightBoxField])}</span>
                            <small class='text-muted'>{rightBoxFieldPostfixValue ? rightBoxFieldPostfixValue(obj[rightBoxFieldPostfixField]) : rightBoxFieldPostfix}</small>
                            {rightBoxFieldBottomValue && <>
                                <small class='text-muted font-weight-lighter'>{rightBoxFieldBottomValue(obj)}</small>
                            </>}
                        </div>
                    </>}
                    {rightBoxTitle && <>
                        <div
                            class={`box-line-right-box mt-1 mb-1 mr-2 py-1  rounded-lg d-flex flex-column justify-content-center align-items-center flex-wrap text-center
                                ${rightBoxClass && rightBoxClass(obj) ? rightBoxClass(obj) : 'text-muted bg-light'}`}
                            style='width: 60px; line-height: 1.0em;'
                        >
                            <span class='font-weight-bold'>{rightBoxValue(obj)}</span>
                            <small>{rightBoxTitle}</small>
                        </div>
                    </>}
                    {/* {obj.images && (showEmptyImage || obj.images[0] || obj.imageUrl) && <div class='box-line-image position-relative' style={`${imgHeight ? `height: ${imgHeight} !important;` : ''}`}>
                        {obj.images[0] ? <>
                            <img src={`${obj.images[0].s3MediumLink}`} class='rounded-lg' style={`${imgHeight ? `height: ${imgHeight} !important;` : ''}`} />
                        </> : <>
                            {obj.imageUrl ? <>
                                <img src={`${obj.imageUrl}`} class='rounded-lg' style={`${imgHeight ? `height: ${imgHeight} !important;` : ''}`} />
                            </> : <>
                                <div class='box-line-image-placeholder border h-100 rounded-lg d-flex justify-content-center align-items-center text-muted' style='font-size: 1.5em; opacity: 0.4;'>
                                    <i class='fa-solid fa-camera-retro' />
                                </div>
                            </>}
                        </>}
                        {opts.showInspections && <div class='box-line-status position-absolute' style='top: 5px; right: 5px;'>
                            {elImageStatus(obj)}
                        </div>}
                        {opts.allowEditQty && showIncDec(obj) && inc && <>
                            <button type='button' class='btn btn-lg btn-success px-1 py-0 position-absolute rounded-pill-both' style='font-size: 1.4em; bottom: 0px; right: 0px;' onClick={inc} data-id={obj.id} data-qty={obj.qty}><i class='fa-solid fa-plus' /></button>
                        </>}
                        {opts.allowEditQty && showIncDec(obj) && dec && <>
                            <button type='button' class='btn btn-lg btn-danger px-1 py-0 position-absolute rounded-pill-both' style='font-size: 1.4em;bottom: 0px; left: 0px;' onClick={dec} data-id={obj.id} data-qty={obj.qty}><i class='fa-solid fa-minus' /></button>
                        </>}
                    </div>} */}

                    {showCevron && <>
                        <div class='box-line-chevron mt-3 mr-3 text-muted'>
                            <i class='fa-solid fa-chevron-right' />
                        </div>
                    </>}
                    {showRightIcon && <>
                        <div class='box-line-icon mt-3 mr-3'>
                            <i class={showRightIcon(obj)} />
                        </div>
                    </>}
                    {/* {remove && <>
                        <button type='button' class='btn btn-lg btn-link' style='font-size: 1.5em;' onClick={remove} data-id={obj.id}><i class='fa-solid fa-trash' /></button>
                    </>} */}
                </div>
            </div>
        </>);
    }

    searchFilter = (el) => {
        const { search } = this.state;

        const searchFields = ['name', 'title', 'firstname', 'lastname', 'sportsClub',
        'email', 'phone', 'address', 'city', 'zip', 'country', 'description', 'tags'];
        if (!search) {
            return true;
        }
        const searchLower = search.toLowerCase();
        for (let i = 0; i < searchFields.length; i += 1) {
            const field = searchFields[i];
            if (el[field] && `${el[field]}`.toLowerCase().includes(searchLower)) {
                return true;
            }
        }
        return false;
    }

    render() {
        const { search, focusedIndex } = this.state;
        const { appState, userStore } = this.props.stores;
        const { isDevelopment, path, apiServer } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const {
            title = '',
            list = [],
            classNames = '',
            helpTextClassNames = '',
            hideEmpty = false,
            addItem,
            filter,
            sortTitle = '',
            sortBy = [],
            sortFunc = () => {},
            showFilterBox = true,
            toggle,
            toggleTitle,
            toggleFunc,
            help,
            showPadding = true,
            loadMore,
            listContainerClassnames = [],
            holdingStore,
        } = this.props;

        if (hideEmpty && list.length === 0) {
            return (<></>);
        }
        // if (list.length === 0) {
        //     return (<>
        //         <div class={`w-100 d-flex flex-column`}>
        //             <Placeholder
        //                 count={1}
        //                 style={{
        //                     marginTop: '0.75em',
        //                     width: '100%',
        //                     height: '400px',
        //                 }}
        //             />
        //             <Placeholder
        //                 count={2}
        //                 style={{
        //                     marginTop: '0.75em',
        //                     marginBottom: '0.75em',
        //                     width: '100%',
        //                     height: '200px',
        //                 }}
        //             />
        //         </div>
        //     </>);
        // }

        let finalList = Array.isArray(list) ? list.filter(this.searchFilter) : [];

        if (sortBy.length > 0) {
            finalList = finalList.sort(fieldSorter([sortTitle]));
        }

        const sortByField = sortTitle.replace(/^-/, '');
        const sortByFieldObj = fields.getField('part', sortByField);

        const sortDir = sortTitle[0] === '-' ? 'desc' : 'asc';

        return (<>
            <div class={`w-100 d-flex flex-column ${classNames}`}>
                {showFilterBox && list.length > 5 && <div class='d-flex flex-row justify-content-center px-3 mb-3'>
                    <div class='input-group'>
                        <div class='input-group-prepend'>
                            <span class='input-group-text rounded-pill-left'>
                                <i class='fa-regular fa-filter'></i>
                            </span>
                        </div>
                        <input
                            class={`form-control ${!search ? 'rounded-pill-right' : ''}`}
                            type='text'
                            value={search}
                            placeholder={`Filter ${title}...`}
                            onInput={this.searchInput}
                        />
                        {search && <div class='input-group-append'>
                            <button class='btn btn-outline-secondary rounded-pill-right' type='button' onClick={this.clearSearch}>
                                <i class='fa-solid fa-circle-xmark'></i>
                            </button>
                        </div>}
                    </div>
                </div>}

                {filter && filter() && <>
                    <div class='d-flex flex-row justify-content-start px-3 mb-3'>
                        <div class='text-muted'>
                            <Text id='list.filtered-by'>Filtered by:</Text>
                        </div>
                        <div class='flex-fill ml-2'>
                            {filter()}
                        </div>
                    </div>
                </>}

                <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between mb-1'>
                    {title}
                    <div>
                        {addItem && <div class='float-right'>
                            <button type='button' class='btn btn-sm btn-outline-secondary rounded-pill' onClick={addItem}><i class='fa-solid fa-plus text-muted' /> <Text id='input.add'>Add</Text></button>
                        </div>}
                        {sortBy.length > 0 && sortFunc && <div class='float-right'>
                            <button type='button' class='btn btn-sm btn-outline-secondary rounded-pill mr-2' onClick={sortFunc}>
                                {sortDir === 'asc' ? <i class='fas fa-arrow-down-wide-short text-muted' /> : <i class='fas fa-arrow-up-short-wide text-muted' />} {sortByFieldObj ? sortByFieldObj.title : sortByField}
                            </button>
                        </div>}
                        {toggleTitle && toggleFunc && <div class='float-right'>
                            <button type='button' class={`btn btn-sm btn-${toggle ? 'success' : 'outline-secondary'} rounded-pill mr-2`} onClick={toggleFunc}>
                                <i class='fas fa-filter' /> {toggleTitle}
                            </button>
                        </div>}
                    </div>
                </div>
                <div class={`d-flex flex-column ${showPadding ? 'mx-3' : ''}`}>
                    {list.length === 0 && <>
                        <div class='w-100 d-flex flex-column px-4 py-3 box-line'>
                            <div class='w-100 d-flex flex-row justify-content-center'>
                                <div class='box-line-add'>
                                    <i class='fa-solid fa-circle-question' />
                                </div>
                            </div>
                        </div>
                    </>}

                    <div class='d-flex flex-column' tabIndex='0' onKeyDown={this.handleKeyDown}>
                        <FastList
                            data={finalList}
                            containerWrapperClassNames={'w-100 d-flex flex-wrap'}
                            listWrapperClassNames={listContainerClassnames}
                            wrapperClassNames={'overflow-hidden box-line'}
                            contentClassNames={`w-100 h-100 d-flex flex-row justify-content-between `}
                            renderContent={this.renderContent}
                            dataFields={['id', 'type']}
                            dataValues={{ type: holdingStore.name }}
                            handleIntersection={this.handleIntersection}
                        />
                    </div>

                </div>
            </div>
            {help && <>
                <div
                    class={`w-100 d-flex justify-content-center px-3 text-center ${helpTextClassNames}`}
                    style='line-height: 1.0em;'
                >
                    <small class='text-muted'>
                        {help}
                    </small>
                </div>
            </>}
            {loadMore && <>
                <div class={`w-100 d-flex justify-content-center px-3 ${classNames}`}>
                    <button
                        type='button'
                        class='btn btn-sm btn-outline-secondary rounded-pill'
                        onClick={loadMore}
                    >
                        <i class='fas fa-arrow-down' /> <Text id='input.load-more'>Load more</Text>
                    </button>
                </div>
            </>}
        </>);
    }
}

export default GuiList;
