import { h, Component } from 'preact';
import { Link } from 'preact-router/match';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import linkState from 'linkstate';
import { route } from 'preact-router';
import { Text, Localizer, withText } from 'preact-i18n';
import Mousetrap from 'mousetrap';

import Logo from '../../components/logo';
import LogoCustomer from '../../components/logo/customer';

import localUtil from '../../lib/util';

import style from './style.css';

const initialState = {
    menu: {
		profile: false,
		main: false,
	},
	searchText: '',
};

const MAX_HEIGHT = '25px';

@withText(props => ({
    allVisitsTitle: <Text id='dyrejournal.all-visits-title'>All visits</Text>,
    reportsTitle: <Text id='dyrejournal.reports-title'>Reports</Text>,
    journalsTitle: <Text id='dyrejournal.journals-title'>Journals</Text>,
    searchTitle: <Text id='dyrejournal.search-title'>Search</Text>,
}))
@observer
class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

	toggleDarkmode = (e) => {
        const { appState } = this.props.stores;
		appState.toggleDarkmode();
	}

	reloadApp = () => {
		window.location.reload(true);
	}

	changeLanguage = (e) => {
		const lang = e.target.value;
		const { userStore } = this.props.stores;
		const { user } = userStore;
		userStore.setLanguage({ email: user.email, language: lang });
	}

	scrollToTop = () => {
		const {  refreshPage = () => {} } = this.props;
		const { appState } = this.props.stores;
		appState.appContainerScrollTop();
		refreshPage();
	}

	addContent = () => {
		const { appState } = this.props.stores;
        const { drawerHeightXSmall } = appState;
        appState.openDrawer('addContent', {
            height: drawerHeightXSmall,
        }, 1);
	}

    search = () => {
		const { appState } = this.props.stores;
        const { drawerHeightLarge } = appState;
        appState.openDrawerRight('search', {
            height: drawerHeightLarge,
			title: 'Search',
        }, 1);
	}

	editInstallation = () => {
		const { id } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('editInstallation', {
			height: drawerHeightMedium,
			id,
			lookupArray: 'parts',
		}, 1);
	}

	inspectInstallation = () => {
		// const { id } = this.props;
		const { appState, installationStore } = this.props.stores;
		const { installation } = installationStore;
		const { id, name } = installation;
		// console.log('inspectInstallation', id);
		const { drawerHeightLarge } = appState;
		appState.openDrawer('inspectInstallation', {
			title: `Inspect: ${name}`,
			height: drawerHeightLarge,
			id,
		}, 1);
	}

	userMenu = () => {
		const { appState, userStore } = this.props.stores;
		const { drawerHeightMedium } = appState;

		const { notifications = [] } = userStore;
		const unreadNotificatins = notifications.filter(e => !e.read);
		const unread = unreadNotificatins.length || 0;
        appState.openDrawer('menu', {
            height: drawerHeightMedium,
        }, 1);
	}

	gotoFrontpage = () => {
		route('/');
	}

	goBack = () => {
		window.history.back();
	}

	goToPage = (url) => {
		route(url);
	}

	onClickNewSale = async e => {
		const { saleStore, appState } = this.props.stores;
        const { path } = appState;
        if (path.match(/^\/dyrejournal-sale\/\d+/)) {
            route(`/dyrejournal-sale`);
        }
        saleStore.createNewSale();
		await appState.checkVersion();
    }

	onClickAllVisits = e => {
		const { drawerLevel = 1, allVisitsTitle } = this.props;
		const { appState } = this.props.stores;

		appState.openDrawerRight('allVisits', {
            hideAdmin: false,
			title: allVisitsTitle,
		}, drawerLevel + 1);
	}

	onClickCalendar = e => {
		route('/calendar/');
	}

	onClickDyrejournal = e => {
		route('/dyrejournal-sale/');
	}

	onClickNotifications = e => {
		route('/calendar/notifications');
	}

	onClickReports = e => {
		const { drawerLevel = 1, reportsTitle } = this.props;
		const { appState } = this.props.stores;

		appState.openDrawerRight('reports', {
            hideAdmin: false,
			title: reportsTitle,
		}, drawerLevel + 1);
	}

	onClickJournals = e => {
		const { drawerLevel = 1, journalsTitle } = this.props;
		const { appState } = this.props.stores;

		appState.openDrawerRight('journals', {
            hideAdmin: false,
			title: journalsTitle,
		}, drawerLevel + 1);
	}

	renderLeftButton = () => {
		const { appState, userStore } = this.props.stores;
		const { user } = userStore;
		const { isApproved, settings = {} } = user;
		const { backgroundColor } = settings;
        const { path, opts, isDevelopment } = appState;

		const { raceStore, raceClassStore } = this.props.stores;

		const darkmode= util.getNestedValue(user, 'settings.darkmode');
		const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;
		if (path.match(/^\/installations/)) {
			return (<>
				<span onClick={this.gotoFrontpage} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class={`fa-regular fa-arrow-left`} /> <span class='d-none d-md-inline-block ml-1'><Text id='header.back'>Back</Text></span>
				</span>
			</>);
		}
		if (path.match(/^\/race\/\d+\/\d+\//)) {
			const { raceClass = {} } = raceClassStore;
			const { id } = raceClass;
			const url = id ? `/raceclass/${id}` : '/race';
			return (<>
				<span onClick={() => url ? this.goToPage(url) : this.goBack()} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`} data-id={id}>
					<i class={`fa-regular fa-arrow-left`} /> <span class='d-none d-md-inline-block ml-1'><Text id='header.back-raceclass'>RaceClass</Text></span>
				</span>
			</>);
		}
		if (path.match(/^\/raceclass\/\d+$/)) {
			const { race = {} } = raceStore;
			const { id } = race;
			const url = id ? `/race/${id}` : '/race';
			return (<>
				<span onClick={() => url ? this.goToPage(url) : this.goBack()} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class={`fa-regular fa-arrow-left`} /> <span class='d-none d-md-inline-block ml-1'><Text id='header.back-race'>Race</Text></span>
				</span>
			</>);
		}
		if (path.match(/^\/raceclass/)) {
			const { raceClass = {} } = raceClassStore;
			const { id } = raceClass;
			const url = id ? `/raceclass/${id}` : '/race';
			return (<>
				<span onClick={() => url ? this.goToPage(url) : this.goBack()} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class={`fa-regular fa-arrow-left`} /> <span class='d-none d-md-inline-block ml-1'><Text id='header.back-raceclass'>RaceClass</Text></span>
				</span>
			</>);
		}
		if (path.match(/^\/race\/\d+$/)) {
			const url = `/`;
			return (<>
				<span onClick={() => url ? this.goToPage(url) : this.goBack()} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class={`fa-regular fa-arrow-left`} /> <span class='d-none d-md-inline-block ml-1'><Text id='header.back-all-races'>All races</Text></span>
				</span>
			</>);
		}
		if (path.match(/^\/race\/\d+\//)) {
			const { race = {} } = raceStore;
			const { id } = race;
			const url = id ? `/race/${id}` : '/';

			return (<>
				<span onClick={() => url ? this.goToPage(url) : this.goBack()} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class={`fa-regular fa-arrow-left`} /> <span class='d-none d-md-inline-block ml-1'><Text id='header.back-race'>Race</Text></span>
				</span>
			</>);
		}
		if (path.match(/^\/race\/$/)) {
			const url = `/`;
			return (<>
				<span onClick={() => url ? this.goToPage(url) : this.goBack()} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class={`fa-regular fa-arrow-left`} /> <span class='d-none d-md-inline-block ml-1'><Text id='header.back-all-races'>All races</Text></span>
				</span>
			</>);
		}
		if (
			isApproved && (path.match(/^\/calendar/))
			|| path.match(/^\/dyrejournal-clinic/)
			|| path.match(/^\/dyrejournal-payments/)
			|| path.match(/^\/dyrejournal-sms/)
			|| path.match(/^\/dyrejournal-mailsent/)
			|| path.match(/^\/dyrejournal-files/)
			|| path.match(/^\/dyrejournal-contacts/)
		) {
			if (opts.showDyrejournal || isDevelopment) {
				return (<>
					<span onClick={this.onClickDyrejournal} class={`islink text-primary ${linkTextClass}`} style={`${linkTextStyle}`}>
						<i class='fa-duotone fa-user-doctor-hair' /> <span class='d-none d-md-inline-block'><Text id='dyrejournal.frontpage'>Dyrejournal</Text></span>
					</span>
				</>);
			}
		}
		if (isApproved && path.match(/^\/dyrejournal-/)) {
			return (<>
				<span onClick={this.onClickAllVisits} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class='fa-duotone fa-list' /> <span class='d-none d-md-inline-block ml-1'><Text id='dyrejournal.all-visits'>Visits</Text></span>
				</span>
				<span onClick={this.onClickCalendar} class={`islink text-primary d-flex align-items-center ${linkTextClass} ml-3`} style={`${linkTextStyle}`}>
					<i class='fa-duotone fa-calendar' /> <span class='d-none d-md-inline-block ml-1'><Text id='dyrejournal.calendar'>Calendar</Text></span>
				</span>
				{/* <span onClick={this.onClickAllVisits} class={`islink text-primary ${linkTextClass} ml-3`} style={`${linkTextStyle}`}>
					<i class='fa-solid fa-gear' /> <span class='d-none d-md-inline-block ml-1'><Text id='dyrejournal.settings'>Settings</Text></span>
				</span> */}
			</>);
		}

		if (path.match(/^\/convention\/\d+/) || path.match(/^\/convention\/signup/)) {
			const url = `/convention/`;

			return (<>
				<span onClick={() => url ? this.goToPage(url) : this.goBack()} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class={`fa-regular fa-arrow-left`} /> <span class='d-none d-md-inline-block ml-1'><Text id='header.back'>Back</Text></span>
				</span>
			</>);
		}

		return (<>
			&nbsp;
			{/* <span onClick={this.addContent} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
				<i class={`fa-regular fa-square-plus`} />
			</span> */}
			{/* {path} */}
		</>);
	}

	renderRightButton = () => {
		const { appState, userStore, saleStore } = this.props.stores;
		const { user, isAdmin } = userStore;
		const { isApproved, isSoknadsguideSuperAdmin } = user;
        const { path, opts = {} } = appState;
		const darkmode= util.getNestedValue(user, 'settings.darkmode');
		const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

		const { newSale } = saleStore;
		const { visitors = [], animals = [], products = [] } = newSale;
		const isNewSale = visitors.length === 0 && animals.length === 0 && products.length === 0;

		if (path.match(/^\/installations/)) {
			return (<>
				{opts.showInspections && <span onClick={this.inspectInstallation} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class={`fa-solid fa-user-magnifying-glass mr-1`} />
				</span>}
				<span onClick={this.editInstallation} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class={`fa-solid fa-pen ml-3 mr-1`} />
				</span>
				<span onClick={this.userMenu} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class={`${path.match(/\/search/) ? 'fa-solid fa-bars' : 'fa-regular fa-bars'} mr-1 ml-3`} />
				</span>
			</>);
		}

		if (isApproved && path.match(/^\/dyrejournal-sale/)) {
			return (<>
				{opts.showInspections && <span onClick={this.inspectInstallation} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class={`fa-duotone fa-user-magnifying-glass mr-1`} />
				</span>}

				{isNewSale ? <>
					<span onClick={this.onClickJournals} class={`islink text-primary d-flex align-items-center ${linkTextClass} mr-3`} style={`${linkTextStyle}`}>
						<i class='fa-duotone fa-book' /> <span class='d-none d-md-inline-block ml-1'><Text id='dyrejournal.journals'>Journals</Text></span>
					</span>
					<span onClick={this.onClickReports} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
						<i class='fa-duotone fa-file-chart-column' /> <span class='d-none d-md-inline-block ml-1'><Text id='dyrejournal.reports'>Reports</Text></span>
					</span>
				</> : <>
					<span onClick={this.onClickNewSale} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
						<i class='fa-duotone fa-house' /> <span class='d-none d-md-inline-block ml-1'><Text id='dyrejournal.main-page'>Main page</Text></span>
					</span>
				</>}
				<span onClick={this.userMenu} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class={`${path.match(/\/search/) ? 'fa-solid fa-bars' : 'fa-regular fa-bars'} mr-1 ml-3`} />
				</span>
			</>);
		}

		if (isApproved && path.match(/^\/calendar\/notifications/)) {
			return (<>
				<span onClick={this.onClickCalendar} class={`islink text-primary d-flex align-items-center ${linkTextClass} ml-3`} style={`${linkTextStyle}`}>
					<i class='fa-duotone fa-calendar' /> <span class='d-none d-md-inline-block ml-1'><Text id='dyrejournal.calendar'>Calendar</Text></span>
				</span>
			</>);
		}
		if (isApproved && path.match(/^\/calendar/)) {
			return (<>
				<span onClick={this.onClickNotifications} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class='fa-duotone fa-bell' /> <span class='d-none d-md-inline-block ml-1'><Text id='dyrejournal.calendar-notifications'>Notifications</Text></span>
				</span>
			</>);
		}

		return (<>
			{isApproved && <>
				<span onClick={this.search} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class={`${path.match(/\/search/) ? 'fa-solid fa-magnifying-glass' : 'fa-regular fa-magnifying-glass'} mr-1 ml-3`} />
				</span>
			</>}
			<span onClick={this.userMenu} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
				<i class={`fa-regular fa-bars mr-1 ml-3`} />
			</span>
			{/* {path} */}
		</>);
	}

	componentDidMount() {
        Mousetrap.bind(['shift+m'], this.userMenu);
    }

    componentWillUnmount() {
        // Unbind shortcuts
        Mousetrap.unbind(['shift+m']);
    }

    render() {
		const { menu, searchText } = this.state;
		const { scrolledDown } = this.props;
        const { appState, userStore, raceStore } = this.props.stores;
        const { viewmode, path, opts = {}, isDevelopment, currentCustomer, isVisibleNavbar } = appState;
		const { allUsers, isAdmin, user, customer, notifications = [], currentEmail, simulatedEmail, simulatedCellphone } = userStore;
		const { settings = {} } = user;
		const { backgroundColor } = settings;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

		const image = localUtil.getImage({ user, customer });
		const displayNameShort = localUtil.displayNameShort(user);
		const displayName = localUtil.displayName(user);

		const unreadNotificatins = notifications.filter(e => !e.read);
		const unread = unreadNotificatins.length || 0;
		const { language = 'en', toggleFeature = {}, hasValidSubscription } = user;
		const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

		// Find servername and display it
		const serverName = window.location.hostname;
		let version = serverName.match(/(dev)/) ? 'beta' : null;
		// version = serverName.match(/(localhost|127.0.0.1)/) ? 'dev' : null;

		const { race } = raceStore;
        const { logo, logoHeight = 80, logoUrl, css } = race;
		const isRace = /^\/(race|raceclass)\/\d+/.test(path) && !/^\/(race|raceclass)\/\d+\/(tracking|admin\/dashboard)/.test(path); // tracking
		const hasHeader = isRace && logo;
		const hasCustomerLogo = opts.showCustomerLogo && currentCustomer?.id && currentCustomer?.logoHeight;

		return (<>
			{hasHeader ? <>
				<div
					class={`fixed-top w-100 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-3 py-2 border-bottom`}
					style={`
						height: ${logoHeight}px;
						transform: ${isVisibleNavbar ? 'translateY(0)' : 'translateY(-100%)'};
						transition: transform 0.3s ease-in-out;
					`}
				>
					<a href={logoUrl}>
						<img src={logo} style={`max-height: ${logoHeight - 10}px;`} />
					</a>
				</div>
			</> : <></>}

			<nav
				class={`navbar navbar-expand-xl navbar-themed ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} fixed-top py-0 ${scrolledDown > 0 ? style.scrolledDown : style.scrolledUp}`}
				style={`
					height: ${hasCustomerLogo ? currentCustomer?.logoHeight : '45px'};
					${hasHeader ? `top: ${logoHeight}px;` : ''}
					${hasCustomerLogo && currentCustomer?.backgroundColorHeader ? `background-color: ${currentCustomer.backgroundColorHeader} !important;` : ''}
					transform: ${isVisibleNavbar ? 'translateY(0)' : `translateY(${hasHeader ? '-300' : '-100'}%)`};
					transition: transform 0.3s ease-in-out;
					${backgroundColor ? `background-color: ${backgroundColor} !important;` : ''}
				`}
			>
				<div class='d-flex justify-content-between align-items-center w-100'>

					<div class={`${opts.showCustomerLogo ? 'w-25' : 'w-25'} d-flex justify-content-start align-items-center`}>
						{this.renderLeftButton()}
					</div>

					<div class='flex-fill d-flex flex-row justify-content-center align-items-center'>
						{opts.showCustomerLogo && currentCustomer?.id && currentCustomer.logoHeight ? <>
							<LogoCustomer stores={this.props.stores} customerid={currentCustomer?.id} logoHeight={currentCustomer.logoHeight || '45px'} />
						</> : <>
							<Logo darkmode={darkmode} stores={this.props.stores} style={'font-size: 1.2em;'} />
							{version && <div
								class='position-absolute'
								style='
									top: 21px;
									left: calc(50% + 60px);
									font-size: 12px;
									color: red;
									font-weight: 700;
								'
							>
								{version}
							</div>}
							{(opts.showDyrejournal || isDevelopment) ? <>
								<div class='d-none d-lg-block'>
									{customer.images && customer.images.length > 0 ? <img src={localUtil.displayImg(customer.images[0], this.props, '220x')} class='img-fluid' style='max-height: 30px;' /> : <></>}
								</div>
							</> : <></>}
						</>}
					</div>

					<div class='w-25 d-flex justify-content-end align-items-center'>
						{this.renderRightButton()}
					</div>

					{/* <div>
						<span class='mt-2 ml-4 position-relative' >
							<a href='/users/notifications' class={`islink text-primary ${linkTextClass}`} style={`${linkTextStyle}`}>
								<i class={`${path.match(/\/users\/notifications/) ? 'fa-solid fa-bell' : 'fa-regular fa-bell'} mr-1`} />
								{unread > 0 && <>
									<span
										class='badge badge-pill badge-danger position-absolute'
										style='font-size: 0.5em; right: -5px; top: -4px; padding: 2px 5px 3px;'
									>{unread > 9 ? '9+' : unread}</span>
								</>}
							</a>
						</span>
					</div> */}

				</div>

				{isAdmin && simulatedEmail ? <div class='position-absolute badge badge-danger' style='top: 0px; left: 250px;'>
					<a href='/admin/user' class='text-white'>
						<span class='font-weight-lighter'>Simulating:</span> {user.firstname} {user.lastname} ({simulatedEmail})
					</a>
				</div> : <></>}

				{isAdmin && simulatedCellphone ? <div class='position-absolute badge badge-danger' style='top: 0px; left: 250px;'>
					<a href='/admin/user' class='text-white'>
						<span class='font-weight-lighter'>Simulating:</span> {user.firstname} {user.lastname} ({simulatedCellphone})
					</a>
				</div> : <></>}

				{user.id ? <div class='position-absolute' style='top: 0px; left: 5px; font-size: 0.5em;'>
					<span class='font-weight-lighter'>Bruker: #{user.id}</span> {user.firstname} {user.lastname} / <span class='font-weight-lighter'>{user.cellphone}</span>
					{hasValidSubscription && <>
						<span class='font-weight-normal bg-danger rounded-pill text-white px-2 ml-2'>
							<i class='fa-solid fa-face-grin-wide'></i> Insider
						</span>
					</>}
				</div> : <></>}

			</nav>
			{/* <xmp>{JSON.stringify(this.props, null, 2)}</xmp> */}
		</>);
	}
}

export default Navigation;
