import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import GuiList from '../../components/gui/list';

function displayImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

@withText(props => ({
    titleText: <Text id='race.title'>Races</Text>,
    signupsText: <Text id='race.signups'>signups</Text>,
    titleYourRacesText: <Text id='race.title-your-races'>Your races</Text>,
    helpYourRacesText: <Text id='race.help-your-races'>This is the races you have signed up to.</Text>,
}))
@observer
class RaceList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    viewRace = e => {
        const { view, preCallback = () => {} } = this.props;
        preCallback();
        if (view) {
            return view(e);
        }
        const { id, title } = e.target.closest('.box-line').dataset;
        route(`/race/${id}`);
    }

    viewSignup = e => {
        const { drawerLevel = 1 } = this.props;
        const { id } = e.target.closest('.box-line').dataset;
        const raceClassContestantId = parseInt(id, 10);

        const { myRaces } = this.props;
        const raceClassId = myRaces.find(r => r.id === raceClassContestantId).raceClass;

		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawerRight('raceSignup', {
            raceClassId,
            isNew: false,
            backButtonText: 'Back',
		}, drawerLevel + 1);
    }

    gotoTracking = e => {
        const { id, title } = e.target.closest('.box-line').dataset;
        route(`/race/${id}/tracking`);
    }

    gotoResults = e => {
        const { id, title } = e.target.closest('.box-line').dataset;
        route(`/race/${id}/results`);
    }

    render() {
        const { appState, userStore, raceStore } = this.props.stores;
        const { isDevelopment, path, apiServer } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const {
            titleText,
            signupsText,
            titleYourRacesText,
            helpYourRacesText,
        } = this.props;
        const {
            races,
            classNames = '',
            hideEmpty,
            showEmptyImage = true,
            title = titleText,
            editElement,
            refreshElement,

            swipeRight1Icon = 'fa-duotone fa-map-location',
            swipeRight1Class = 'success',
            swipeRight1Title = 'Tracking',
            swipeRight1Func = this.gotoTracking,

            swipeRight2Icon = 'fa-duotone fa-list',
            swipeRight2Class = 'primary',
            swipeRight2Title = 'Resultater',
            swipeRight2Func = this.gotoResults,

            rightBoxField = 'startDate',
            rightBoxFieldValue = e => util.formatDate(e, { month: 'short', day: 'numeric', locale: 'nb-NO' }, true),
            rightBoxFieldPostfix,
            rightBoxFieldPostfixField = 'startDate',
            rightBoxFieldPostfixValue = e => util.formatDate(e, { year: 'numeric', locale: 'nb-NO' }, true),
            rightBoxFieldBottomValue = e => e.totalContestants ? <><i class='fa-duotone fa-user' /> {e.totalContestants}</> : '',
            help,
            children,
            showYourRaces = true,
            myRaces = [],
        } = this.props;

        return (<>
            {showYourRaces && <>
                <GuiList
                    stores={this.props.stores}
                    holdingStore={raceStore}
                    classNames={classNames}
                    helpTextClassNames={'mb-5'}
                    view={this.viewSignup}
                    title={titleYourRacesText}
                    list={myRaces}
                    elTitle={e => e.raceTitle}
                    // elInfo1={e => e.signupStartDate ? <><i class='fa-duotone fa-user-plus' /> {util.formatDate(e.signupStartDate, { hour12: false, hour: '2-digit', minute: '2-digit' })}</> : ''}
                    elInfo1={e => e.raceClassTitle}
                    elInfo2={e => ''}
                    elInfo3={e => ''}
                    showEmptyImage={showEmptyImage}
                    hideEmpty={hideEmpty}
                    editElement={editElement}
                    rightBoxField={rightBoxField}
                    rightBoxFieldValue={rightBoxFieldValue}
                    rightBoxFieldPostfix={rightBoxFieldPostfix}
                    rightBoxFieldPostfixField={rightBoxFieldPostfixField}
                    rightBoxFieldPostfixValue={rightBoxFieldPostfixValue}
                    help={helpYourRacesText}
                />
            </>}

            <GuiList
                stores={this.props.stores}
                holdingStore={raceStore}
                classNames={classNames}
                view={this.viewRace}
                title={title}
                list={races}
                elTitle={e => e.title}
                // elInfo1={e => e.signupStartDate ? <><i class='fa-duotone fa-user-plus' /> {util.formatDate(e.signupStartDate, { hour12: false, hour: '2-digit', minute: '2-digit' })}</> : ''}
                elInfo1={e => e.ingress}
                elInfo2={e => ''}
                qty={e => {
                    const isDone = e.endDate && new Date(e.endDate) < new Date();
                    const isActive = e.startDate && e.endDate && new Date(e.startDate) < new Date() && new Date(e.endDate) > new Date();
                    const diff = util.dateDiff(new Date(), e.startDate);
                    // console.log({ diff })
                    const daysLeft = diff.days + 1;
                    return isDone ? <>
                        <i class='fa-duotone fa-check text-success' />
                        {e.hideFromList ? <i class='fa-duotone fa-eye-slash text-danger' /> : ''}
                    </> : <>
                        <div class='d-flex flex-column justify-content-center align-items-center' style='font-size: 20px;'>
                            {isActive ? <>
                                <div class='spinner-grow text-danger' role='status'>
                                    <span class='sr-only'>Active...</span>
                                </div>
                            </> : <>
                                {daysLeft}
                                <span style='font-size: 9px; line-height: 10px;'>dager igjen</span>
                            </>}
                            {e.hideFromList ? <i class='fa-duotone fa-eye-slash text-danger' /> : ''}
                        </div>
                    </>;
                }}
                // elInfo3={e => e.totalContestants ? `${e.totalContestants} ${signupsText}` : ''}
                showEmptyImage={showEmptyImage}
                hideEmpty={hideEmpty}
                editElement={editElement}
                refreshElement={refreshElement}
                showFilterBox={false}

                swipeRight1Icon={swipeRight1Icon}
                swipeRight1Class={swipeRight1Class}
                swipeRight1Title={swipeRight1Title}
                swipeRight1Func={swipeRight1Func}

                swipeRight2Icon={swipeRight2Icon}
                swipeRight2Class={swipeRight2Class}
                swipeRight2Title={swipeRight2Title}
                swipeRight2Func={swipeRight2Func}

                rightBoxField={rightBoxField}
                rightBoxFieldValue={rightBoxFieldValue}
                rightBoxFieldPostfix={rightBoxFieldPostfix}
                rightBoxFieldPostfixField={rightBoxFieldPostfixField}
                rightBoxFieldPostfixValue={rightBoxFieldPostfixValue}
                rightBoxFieldBottomValue={rightBoxFieldBottomValue}
                help={help || children}
            />
        </>);
    }
}

export default RaceList;
