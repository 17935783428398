import { h, Component } from 'preact';
import util from 'preact-util';
import { observer, createRef } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import Hls from 'hls.js';

import localUtil from '../../lib/util';

@observer
class VideoStreamingPlayer extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
        this.playerRef = {};
        this.hls = {};
    }

    setPlayerRef = (playerRef) => {
        const { videoStreamUrl } = this.props;
        this.playerRef[videoStreamUrl] = playerRef;
        this.loadHls(playerRef);
    }

    loadHls = (playerRef) => {
        const { hlsIsSupported = false } = this.state;
        const { videoStreamUrl } = this.props;
        if (Hls.isSupported()) {
            this.hls[videoStreamUrl] = new Hls({
                // Increase buffer length for better play stability on slow connections
                maxMaxBufferLength: 300, // Buffer up to 30 seconds of video

                // Increase the live sync and latency durations
                liveSyncDuration: 15, // Target duration of 15 seconds for live sync
                liveMaxLatencyDuration: 60, // Allow up to 20 seconds latency

                // Back buffer length - you might want to increase this to allow for rewinding
                backBufferLength: 600, // Keep 60 seconds of video in buffer behind the playhead

                // Increase nudge retries
                nudgeMaxRetry: 15, // More retries for recovering from a stalled state

                // Additional settings to consider
                maxBufferSize: 600 * 1000 * 1000, // Maximum buffer size in bytes
                maxBufferLength: 1200, // Maximum buffer length in seconds
                lowLatencyMode: false, // Disable low latency mode for better buffering
            });
            this.hls[videoStreamUrl].on(Hls.Events.ERROR, (event, data) => {
                if (data.fatal) {
                    switch (data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            // Attempt to recover from network errors
                            console.log('Fatal network error encountered, trying to recover.');
                            this.hls[videoStreamUrl].startLoad(); // Try reloading the stream
                            break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            console.log('Fatal media error encountered, trying to recover.');
                            this.hls[videoStreamUrl].recoverMediaError(); // Attempt to recover from media errors
                            break;
                        default:
                            console.log('Fatal error encountered, cannot recover.');
                            // If recovery is not possible, consider gracefully destroying the Hls instance
                            // and perhaps signaling to the user that playback cannot continue.
                            this.hls[videoStreamUrl].destroy();
                            break;
                    }
                }
            });
            if (playerRef) {
                playerRef.addEventListener('play', () => {
                    console.log('Event "play": Attempting to play video:', videoStreamUrl);
                    if (!this.hls[videoStreamUrl].url) {
                        this.hls[videoStreamUrl].loadSource(videoStreamUrl);
                        this.hls[videoStreamUrl].attachMedia(playerRef);
                        this.hls[videoStreamUrl].on(Hls.Events.MANIFEST_PARSED, () => {
                            playerRef.play().catch(e => console.error("Error attempting to play video:", e));
                        });
                    }
                }, { once: true });
            }

            // this.hls[videoStreamUrl].loadSource(videoStreamUrl);
            // this.hls[videoStreamUrl].attachMedia(playerRef);
        // } else if (playerRef && playerRef.canPlayType('application/vnd.apple.mpegurl')) {
        //     playerRef.addEventListener('play', () => {
        //         playerRef.src = videoStreamUrl;
        //     }, { once: true });
        } else {
            // playerRef.src({
            //     src: videoStreamUrl,
            //     type: 'application/x-mpegURL' // HLS MIME type
            // });
        }
    }

    cleanupVideoPlayer = () => {
        const { videoStreamUrl } = this.props;
        const { hlsIsSupported = false } = this.state;
        if (this.playerRef[videoStreamUrl] && Hls.isSupported()) {
            console.log('Unmounting video player');
            this.playerRef[videoStreamUrl].pause();
            this.playerRef[videoStreamUrl].src = ''; // Remove the source
            // this.playerRef[videoStreamUrl].removeAttribute('src'); // Ensure the source is fully removed
            this.playerRef[videoStreamUrl].load(); // Load the empty source
            // this.playerRef[videoStreamUrl].play();
            try {
                this.playerRef[videoStreamUrl].remove();
            } catch(e){
                console.log(e);
            }
            this.playerRef[videoStreamUrl] = null;
        }

        // console.log('this.hls[videoStreamUrl]', this.hls[videoStreamUrl]);
        if (this.hls[videoStreamUrl] && Hls.isSupported()) {
            this.hls[videoStreamUrl].stopLoad();
            this.hls[videoStreamUrl].detachMedia();
            this.hls[videoStreamUrl].destroy(); // This will clean up the hls.js instance
            // console.log('this.hls[videoStreamUrl]', this.hls[videoStreamUrl]);
            this.hls[videoStreamUrl] = null;
            // console.log('this.hls[videoStreamUrl]', this.hls[videoStreamUrl]);
        }
    }

    componentDidMount() {
        const hlsIsSupported = Hls.isSupported();
        this.setState({
            hlsIsSupported,
        });
    }

    componentWillUnmount() {
        this.cleanupVideoPlayer();
    }

    render() {
        const { videoStreamUrl, poster } = this.props;

        return (<>
            {videoStreamUrl ? <>
                <video
                    preload='none'
                    ref={this.setPlayerRef}
                    playsInline
                    muted={false}
                    controls
                    style='width: 100%; height: 100%; border-radius: 20px;'
                    src={videoStreamUrl}
                    type={'application/x-mpegURL'}
                    poster={poster}
                />
            </> : <>
                No video stream URL
            </>}
        </>);
    }
}

export default VideoStreamingPlayer;
