import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class JournalPrint extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { week, isNew, drawerLevel, journals = [] } = this.props;
        const { sections } = this.state;
        const { customerStore, userStore, saleStore, journalStore } = this.props.stores;
        const { customer } = userStore;
        const { user, isAdmin } = userStore;

        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const {
            saleTotal = 0,
            saleTax = 0,
            newSale = {},
        } = saleStore;
        // const saleIsLocked = !!newSale.paymentMethod;
        // console.log({ newSale })

        const { animals, owners } = journalStore;

        return (<>
            <div class='receipt'>
                <div class='container receipt'>
                    <div class='row'>
                        <div class='col-4 text-center d-flex align-items-center'>
                            {customer.images && customer.images.length > 0 && <img src={localUtil.displayImg(customer.images[0], this.props, '220x')} class='img-fluid' />}
                        </div>
                        <div class='col-8 d-flex align-items-center'>
                            <h3>{customer.name}</h3>
                        </div>
                    </div>
                    <div class='row mt-5'>
                        <div class='col-12 d-flex flex-column align-items-end'>
                            <div class='d-flex flex-row'><Text id='dyrejournal.date'>Date:</Text> {util.formatDate(new Date(), { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}</div>
                        </div>
                    </div>

                    <hr />

                    <div class='row'>
                        <div class='col-12'>


                            <div class={`w-100 px-2 py-2 px-3 mt-2 d-flex flex-row ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg`}>
                                <div class='w-20 font-weight-bold'>
                                    <Text id='dyrejournal.animal-owner'>Owner</Text>
                                </div>
                                <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                    {owners.map(owner => {
                                        return (<>
                                            <div class='d-flex flex-column justify-content-start'>
                                                <div class=''>
                                                    {owner.firstname} {owner.lastname}
                                                </div>
                                                <div class=''>
                                                    {owner.address}
                                                </div>
                                                <div class=''>
                                                    {fields.getNumberFixedWidth(owner.postalcode)} {owner.place}
                                                </div>
                                                <div class=''>
                                                    {owner.email}
                                                </div>
                                                <div class=''>
                                                    {owner.cellphone}
                                                </div>
                                            </div>
                                        </>);
                                    })}
                                    {/* <xmp>{JSON.stringify(owners, null, 4)}</xmp> */}
                                </div>
                            </div>

                            <div class={`w-100 px-3 py-2 mt-3 d-flex flex-row ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg`}>
                                <div class='w-20 font-weight-bold'>
                                    <Text id='dyrejournal.animal-title'>Animal</Text>
                                </div>
                                <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                    {animals.map(animal => <>
                                        {animal.name}, {animal.species}, {animal.breed}{animal.birth && <>, f.{util.formatDate(animal.birth, { locale: 'nb-NO' })} ({util.age(animal.birth)})</>},
                                        {animal.chipId ? <><br />ChipID: {animal.chipId}</> : ''}
                                    </>)}
                                    {/* <xmp>{JSON.stringify(animal, null, 4)}</xmp> */}
                                </div>
                            </div>


                            {journals.length > 0 && journals.map(journal => {
                                return (<>
                                    <div class={`w-100 px-3 py-2 mt-3 d-flex flex-column ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg`}>
                                        <div class='d-flex flex-row justify-content-start'>
                                            <div class='w-20 font-weight-bold'>
                                                <Text id='dyrejournal.journal-number'>Journal number</Text>
                                            </div>
                                            <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                                {journal.journalNumber || journal.id}
                                            </div>
                                        </div>
                                        <div class='d-flex flex-row justify-content-start'>
                                            <div class='w-20 font-weight-bold'>
                                                <Text id='dyrejournal.journal-date'>Journal date</Text>
                                            </div>
                                            <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                                {journal.date && util.formatDate(journal.date, {  hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                            </div>
                                        </div>
                                        <div class='d-flex flex-row justify-content-start mt-3'>
                                            <div class='w-20 font-weight-bold'>
                                                <Text id='dyrejournal.responsible-title'>Responsible</Text>
                                            </div>
                                            <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                                {journal.veterinaryName}
                                            </div>
                                        </div>
                                        <div class='d-flex flex-row justify-content-start mt-3'>
                                            <div class='w-20 font-weight-bold'>
                                                <Text id='animal.journal-chiefComplaint'>chiefComplaint</Text>
                                            </div>
                                            <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                                {/* {journal.chiefComplaint} */}
                                                <Markdown markdown={journal.chiefComplaint} markedOpts={localUtil.getMarkdownOptions()} />
                                            </div>
                                        </div>

                                        {journal.physicalExaminationFindings && <>
                                            <div class='d-flex flex-row justify-content-start mt-3'>
                                                <div class='w-20 font-weight-bold'>
                                                    <Text id='animal.journal-physicalExaminationFindings'>physicalExaminationFindings</Text>
                                                </div>
                                                <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                                    {/* {journal.physicalExaminationFindings} */}
                                                    <Markdown markdown={journal.physicalExaminationFindings} markedOpts={localUtil.getMarkdownOptions()} />
                                                </div>
                                            </div>
                                        </>}
                                        {journal.diagnosis && <>
                                            <div class='d-flex flex-row justify-content-start mt-3'>
                                                <div class='w-20 font-weight-bold'>
                                                    <Text id='animal.journal-diagnosis'>diagnosis</Text>
                                                </div>
                                                <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                                    {/* {journal.diagnosis} */}
                                                    <Markdown markdown={journal.diagnosis} markedOpts={localUtil.getMarkdownOptions()} />
                                                </div>
                                            </div>
                                        </>}
                                        <div class='d-flex flex-row justify-content-start mt-3'>
                                            <div class='w-20 font-weight-bold'>
                                                <Text id='animal.journal-treatmentPlan'>treatmentPlan</Text>
                                            </div>
                                            <div class='w-80 d-flex flex-column justify-content-start ml-3 flex-fill'>
                                                {/* {journal.treatmentPlan} */}
                                                <Markdown markdown={journal.treatmentPlan} markedOpts={localUtil.getMarkdownOptions()} />
                                            </div>
                                        </div>
                                        {/* <xmp>{JSON.stringify(journal, null, 4)}</xmp> */}
                                    </div>
                                </>);
                            })}

                        </div>
                    </div>


                    <hr class='mt-5' />

                    <div class='row'>
                        <div class='col-3 d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.business-register'>The Business Register</Text></div>
                                <div>{customer.orgNumber}</div>
                                <div>{customer.name}</div>
                            </small>
                        </div>
                        <div class='col-3 d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.address'>Address</Text></div>
                                <div>{customer.address}</div>
                                <div>{customer.postalcode} {customer.postalplace}</div>
                            </small>
                        </div>
                        <div class='col-3 d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.phone-fax'>Phone/Fax</Text></div>
                                <div>{customer.phone}</div>
                                <div>{customer.fax}</div>
                            </small>
                        </div>
                        <div class='col-3 d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.internett'>Internet</Text></div>
                                <div>{customer.url}</div>
                                <div>{customer.email}</div>
                            </small>
                        </div>
                    </div>

                </div>

            </div>
        </>);
    }
}

export default JournalPrint;
