import { h, render, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { IntlProvider, Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';
import DyrejournalReportPrint from '../../components/dyrejournal/reportPrint';
import Pagination from '../../components/gui/pagination';

import definitionNo from '../../languages/no.json';
import definitionEn from '../../languages/en.json';

const LIMIT = 100;

const countryMap = {
    no: definitionNo,
    en: definitionEn,
    default: definitionEn,
};

const fieldSorter = (fields) => (a, b) => fields.map(o => {
    let dir = 1;
    if (o[0] === '-') {
        dir = -1;
        o = o.substring(1);
    }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
}).reduce((p, n) => p ? p : n, 0);

function getInitials(name) {
    if (!name) return '';
    return name
        .split(' ')  // Split the name into an array of words
        .filter(word => word.length > 0)  // Filter out any empty strings
        .map(word => word[0].toUpperCase())  // Map each word to its first character and convert to uppercase
        .join('');  // Join the initials into a single string
}

@withText(props => ({
    productTitle: <Text id='dyrejournal.product.search-title'>Search for product</Text>,
    searchTitle: <Text id='dyrejournal.journal-search-title'>Search for journals</Text>,
    unlockAreYouSure: <Text id='dyrejournal.journal-unlock-areyousure'>Are you sure you want to unlock journal?</Text>,
    animaliaSearchTitle: <Text id='dyrejournal.journal-animalia-search-title'>Search for individuals in DHP</Text>,
}))
@observer
class AllJournals extends Component {
	constructor(props) {
        super(props);
        this.state = {
            search: '',
            veterinary: '',
            currentPage: 1,
            sections: fields.get('journal'),
            reportDate: util.isoDate(new Date(), false, false, true),
        };
        this.searchTimer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { search, currentPage, showDeleted, cutoffDate, cutoffDateEnd, activeStatus, veterinary } = this.state;

        const { journalStore } = this.props.stores;
        const response = await journalStore.load({
            query: {
                search,
                showDeleted,
                cutoffDate,
                cutoffDateEnd,
                veterinary,
            },
            skipUpdate: true,
            offset: (currentPage - 1) * LIMIT,
            limit: LIMIT,
            addData: ['animals', 'visitors', 'veterinaries', 'totalJournals'],
        });
        journalStore.updateKeyValue('allJournals', response);
        await journalStore.loadVeterinaries();
    }

    saveSale = async (props) => {
        const { saleStore } = this.props.stores;
        await saleStore.saveSale(props);
    }

    loadJournals = async animalIds => {
        const { journalStore, saleStore } = this.props.stores;
        const response = await journalStore.load({
            query: {
                animal: animalIds,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('animalJournals', response);
    }

    onClickEditJournal = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { id, sale } = e.target.closest('.journalLine').dataset;
        const {
            drawerLevel = 1,
            saleIsLocked = false,
        } = this.props;
		const { appState, saleStore, journalStore } = this.props.stores;

        const newSale = await saleStore.load(parseInt(sale, 10), false, { skipUpdate: true });
        let animals = [];
        if (newSale && newSale.length > 0) {
            saleStore.createNewSale({ skipAnimalJournals: true });
            if (newSale[0].animals) {
                animals = newSale[0].animals;
                const journalIds = newSale[0].journals.map(e => e.id);
                const animalIds = newSale[0].animals.map(e => e.id);
                await this.loadJournals(animalIds, newSale[0].id, journalIds);
            }
            saleStore.updateKeyValue('newSale', newSale[0]);
        }

		appState.openDrawerRight('editJournal', {
            isNew: false,
            hideAdmin: true,
            saveSale: this.saveSale,
            saleIsLocked,
            id: parseInt(id, 10),
            animals,
            callback: async () => {
                // console.log('onClickEditJournal callback');
                const { newJournal } = journalStore;
                const { newSale } = saleStore;
                const { journals } = newSale;
                const idx = journals.findIndex(a => a.id === newJournal.id);
                if (idx > -1) {
                    saleStore.updateObjectKeyArrValue('newSale', 'journals', idx, newJournal);
                    await this.saveSale({ calledFrom: 'journals.onClickEditJournal.callback' });
                } else {
                    saleStore.updateObjectKeyValue('newSale', 'journals', [...journals, newJournal]);
                    await this.saveSale({ calledFrom: 'journals.onClickEditJournal.callback' });
                }
                this.loadAll();
            },
		}, drawerLevel + 1);
    }

    onClickViewJournal = e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('.journalLine').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightLarge } = appState;

        appState.openDrawer('viewJournal', {
            hideAdmin: true,
            height: drawerHeightLarge,
            id: parseInt(id, 10),
        }, drawerLevel + 2);
    }

    onClickUnlock = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('.journalLine').dataset;
        const { journalStore } = this.props.stores;
        const { unlockAreYouSure } = this.props;

        if (!confirm(unlockAreYouSure)) {
            return;
        }

        const response = await journalStore.unlock(parseInt(id, 10));
        if (response.status === 202) {
            this.loadAll();
        }
    }

    searchInput = (e) => {
        const search = e.target.value;
        this.setState({ search });

        clearTimeout(this.searchTimer);
        if (search.length >= 3) {
            this.searchTimer = setTimeout(async () => {
                this.doSearch(search);
            }, 300);
        }
    }

    async doSearch(search) {
        const { journalStore } = this.props.stores;
        if (search) {
            const response = await journalStore.load({ query: { search }, limit: 200, skipUpdate: true, addData: ['animals', 'visitors', 'veterinaries'] });
            journalStore.updateKeyValue('allJournals', response);
        } else {
            const response = await journalStore.load({ query: { }, limit: 200, skipUpdate: true, addData: ['animals', 'visitors', 'veterinaries'] });
            journalStore.updateKeyValue('allJournals', response);
        }
    }

    clearSearch = () => {
        this.setState({ search: '' });
        this.doSearch('');
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.doSearch(this.state.search);
        } else if (e.key === 'Escape') {
            e.preventDefault();
            this.clearSearch();
        }
    }

    setPage = (page) => {
        this.setState({
            currentPage: page,
        }, () => {
            this.loadAll();
        });
    }

    toggleShowDeleted = async e => {
        const { checked } = e.target;
        this.setState({
            showDeleted: checked,
        }, () => {
            this.loadAll();
        });
    }

    changeCutoffDate = async e => {
        const { value: cutoffDate } = e.target;
        this.setState({ cutoffDate }, () => {
            this.loadAll();
        });
    }

    changeCutoffDateEnd = async e => {
        const { value: cutoffDateEnd } = e.target;
        this.setState({ cutoffDateEnd }, () => {
            this.loadAll();
        });
    }

    changeVeterinary = async e => {
        const { value: veterinary } = e.target;
        this.setState({ veterinary }, () => {
            this.loadAll();
        });
    }

    onClickGoToToday = e => {
        this.setState({
            cutoffDate: util.isoDate(new Date(), false, false, true),
        }, () => {
            this.loadAll();
        });
    }

    clearCutoffDate = e => {
        this.setState({ cutoffDate: null }, () => {
            this.loadAll();
        });
    }

    clearCutoffDateEnd = e => {
        this.setState({ cutoffDateEnd: null }, () => {
            this.loadAll();
        });
    }

    onClickToggleFormatting = e => {
        const { formatting } = this.state;
        this.setState({ formatting: !formatting });
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const {
            search,
            activeStatus,
            currentPage = 0,
            cutoffDate,
            cutoffDateEnd,
            formatting = true,
            animaliaSearch,
        } = this.state;
        const {
            productTitle,
            searchTitle,
            animaliaSearchTitle,
        } = this.props;
        const { userStore, journalStore } = this.props.stores;
        const { user, isAdmin, isAnimaliaActive } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { allJournals, visitors, animals, veterinaries, totalJournals } = journalStore;

        const apiServer = util.getApiServer();
        const jwtToken = util.getJwtToken();

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='padding-top: 0px; padding-bottom: 100px;'>

                    <div class='w-100 d-flex flex-column'>
                        <div class='w-100 d-flex flex-column'>

                            <div class={`d-flex flex-column mx-3 px-3 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>

                                <div class='d-flex flex-row justify-content-between'>
                                    <div class='input-group'>
                                        <div class='input-group-prepend'>
                                            <span class='input-group-text rounded-pill-left'>
                                                <i class='fa-regular fa-magnifying-glass'></i>
                                            </span>
                                        </div>
                                        <input
                                            class={`form-control ${!search ? 'rounded-pill-right' : ''}`}
                                            type='text'
                                            value={search}
                                            placeholder={`${searchTitle}`}
                                            onInput={this.searchInput}
                                            onKeyDown={this.handleKeyDown}
                                        />
                                        {search && <div class='input-group-append'>
                                            <button class='btn btn-danger rounded-pill-right' type='button' onClick={this.clearSearch}>
                                                <i class='fa-solid fa-circle-xmark'></i>
                                            </button>
                                        </div>}
                                    </div>
                                </div>
                                <div class='d-flex flex-row justify-content-end mt-1'>
                                    <div class='input-group'>
                                        {veterinaries && veterinaries.length > 0 && <>
                                            <select
                                                class='form-control rounded-pill'
                                                onInput={this.changeVeterinary}
                                            >
                                                <option value=''>-- <Text id='dyrejournal.choose-vet'>Choose veterinary</Text> --</option>
                                                {veterinaries.map(vet => (
                                                    <option value={vet.id}>{`${vet.firstname} ${vet.lastname}`}</option>
                                                ))}
                                            </select>
                                        </>}
                                    </div>
                                    <div class='input-group ml-2'>
                                        <div class='input-group-prepend'>
                                            <span class='input-group-text rounded-pill-left'>
                                                <i class='fa-regular fa-timer mr-2'></i> Fra
                                            </span>
                                        </div>
                                        <input
                                            class={`form-control rounded-${cutoffDate ? 'none' : 'pill-right'}`}
                                            type='date'
                                            onInput={this.changeCutoffDate}
                                            value={cutoffDate}
                                        />
                                        {cutoffDate && <div class='input-group-append'>
                                            <button class='btn btn btn-danger rounded-pill-right' type='button' onClick={this.clearCutoffDate}>
                                                <i class='fa-solid fa-circle-xmark'></i>
                                            </button>
                                        </div>}
                                    </div>
                                    <div class='input-group ml-2'>
                                        <div class='input-group-prepend'>
                                            <span class='input-group-text rounded-pill-left'>
                                                <i class='fa-regular fa-timer mr-2'></i> Til
                                            </span>
                                        </div>
                                        <input
                                            class={`form-control rounded-${cutoffDateEnd ? 'none' : 'pill-right'}`}
                                            type='date'
                                            onInput={this.changeCutoffDateEnd}
                                            value={cutoffDateEnd}
                                        />
                                        {cutoffDateEnd && <div class='input-group-append'>
                                            <button class='btn btn-sm btn-danger rounded-pill-right' type='button' onClick={this.clearCutoffDateEnd}>
                                                <i class='fa-solid fa-circle-xmark'></i>
                                            </button>
                                        </div>}
                                    </div>
                                    {/* <button
                                        type='button'
                                        class='btn btn-sm btn-outline-primary rounded-pill ml-2'
                                        onClick={this.onClickGoToToday}
                                    >
                                        <nobr><Text id='dyrejournal.goto-today'>Goto today</Text></nobr>
                                    </button> */}
                                    {/* <button
                                        type='button'
                                        class='btn btn-sm btn-outline-primary rounded-pill ml-2'
                                        onClick={this.onClickPrintSales}
                                    >
                                        <nobr><i class='fa-duotone fa-print' /> <Text id='dyrejournal.print-sales'>Print sales</Text></nobr>
                                    </button> */}
                                    {/* <button
                                        type='button'
                                        class={`btn btn-sm btn${formatting ? '' : '-outline'}-primary rounded-pill ml-2`}
                                        onClick={this.onClickToggleFormatting}
                                    >
                                        <nobr><i class='fa-duotone fa-list' /> <Text id='dyrejournal.toggle-formatting'>Formatting</Text></nobr>
                                    </button> */}
                                    {/* <a
                                        href={`${apiServer}${journalStore.api?.load?.url}csv?jwtToken=${jwtToken}&status=${activeStatus || ''}&cutoffDate=${cutoffDate || ''}&cutoffDateEnd=${cutoffDateEnd || ''}&search=${search || ''}`}
                                        target='_blank'
                                        class={`btn btn-sm btn-outline-primary rounded-pill ml-2 pt-2`}
                                    >
                                        <nobr><i class='fa-duotone fa-download' /> <Text id='dyrejournal.download-csv'>Download CSV</Text></nobr>
                                    </a> */}
                                </div>
                                {/* <div class='d-flex flex-row justify-content-end mt-1'>
                                    <div class='form-check form-check-inline'>
                                        <nobr>
                                            <input type='checkbox' class='form-check-input form-input-sm' id='showDeleted' onInput={this.toggleShowDeleted} />
                                            <label class='form-check-label' for='showDeleted'><Text id='dyrejournal.show-deleted'>Show deleted</Text></label>
                                        </nobr>
                                    </div>
                                </div> */}


                                <div class='table-responsive mt-3'>
                                    <table class='table table-striped table-sm'>
                                        <thead class=''>
                                            <tr>
                                                <th scope='col'>&nbsp;</th>
                                                <th scope='col'><Text id='animal.journal-id'>Id</Text></th>
                                                {isAdmin && <th scope='col'><Text id='animal.journal-saleNumber'>saleNumber</Text></th>}
                                                <th scope='col'><Text id='animal.journal-vet'>Vet</Text></th>
                                                <th scope='col'><Text id='animal.journal-date'>Journal date</Text></th>
                                                <th scope='col'><Text id='animal.journal-animal'>Animal</Text></th>
                                                <th scope='col'><Text id='animal.journal-visitor'>Visitor</Text></th>
                                                <th scope='col'><Text id='animal.journal-chiefComplaint'>chiefComplaint</Text></th>
                                                <th scope='col'><Text id='animal.journal-treatmentPlan'>treatmentPlan</Text></th>
                                                <th scope='col'><Text id='animal.journal-comment'>comment</Text></th>
                                                <th scope='col'>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allJournals.sort(fieldSorter(['-date'])).map(j => {
                                                const diff = util.dateDiff(j.date);
                                                const lineSaleIsLocked = j.closed;
                                                const animal = animals.find(a => a.id === j.animal) || {};
                                                const visitor = visitors.find(v => v.id === j.visitor) || {};
                                                const veterinary = veterinaries.find(vet => vet.id === j.veterinary);
                                                const veterinaryName = veterinary ? `${veterinary.firstname} ${veterinary.lastname}` : j.veterinaryName;
                                                const vetInitials = getInitials(veterinaryName);

                                                return (<>
                                                    <tr
                                                        class={`journalLine ${lineSaleIsLocked ? '' : 'font-weight-bold'} ${j.offline ? 'deleted text-muted' : ''}`}
                                                        onClick={lineSaleIsLocked ? this.onClickViewJournal : this.onClickEditJournal}
                                                        data-id={j.id}
                                                        data-sale={j.sale}
                                                        // style={`${lineSaleIsLocked ? 'background-color: rgba(144, 238, 144, 0.1);' : ''}`}
                                                    >
                                                        <td style='font-size: 1.5em;'>
                                                            {lineSaleIsLocked ? <i class='fas fa-square-check text-success'></i> : <i class='fas fa-pen-to-square text-danger'></i>}
                                                        </td>
                                                        <td>{j.journalNumber || j.id}</td>
                                                        {isAdmin && <td>{j.saleNumber}</td>}
                                                        <td>{vetInitials}</td>
                                                        <td>
                                                            <nobr>{util.formatDate(j.date, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}</nobr>
                                                        </td>
                                                        <td>
                                                            {animal.name} ({animal.species}/{animal.breed})
                                                        </td>
                                                        <td>{visitor.firstname} {visitor.lastname}</td>
                                                        <td>{j.chiefComplaint}</td>
                                                        <td>{j.treatmentPlan}</td>
                                                        <td>{j.title ? <span class='font-weight-bold mr-1'>{j.title}</span> : ''}{j.comment}</td>
                                                        <td
                                                            onClick={lineSaleIsLocked ? this.onClickUnlock : () => {}}
                                                        >
                                                            {lineSaleIsLocked ? <i class='fa-duotone fa-unlock' /> : ''}
                                                        </td>
                                                    </tr>
                                                </>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                    </div>

                    <Pagination total={totalJournals} limit={LIMIT} current={currentPage} onPageChange={this.setPage} stores={this.props.stores} />

                </div>
            </div>
        );
    }
}

export default AllJournals;
