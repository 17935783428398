import { h, Component } from 'preact';
import util from 'preact-util';
import { route } from 'preact-router';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@observer
class UserProfileInline extends Component {
  	constructor(props) {
        super(props);
        const { appState } = this.props.stores;
        const { opts = {} } = appState;
        // const sections = ['User basics', 'User address', 'User settings', 'User email settings', 'Images, files and links'];
        const sections = ['User basics'];
        const hideFields = [
            { name: 'cellphoneShadow' },
            { name: 'cellphone' },
            { name: 'email' },
            // { name: 'birth' },
        ];

        if (opts && (opts.showRace || opts.showShooting)) {
            sections.push('Race qualifying', 'Media', 'About yourself', 'User email settings')
            hideFields.push(
                { name: 'animaliaVeterinaryNumber' },
                { name: 'hprNumber' },
                { name: 'code' },
            );
        }

        if (opts && opts.showDyrejournal) {
            hideFields.push(
                { name: 'licenseNumber' },
                { name: 'sportsClub' },
                { name: 'sportsAssociation' },
                { name: 'kennel'},
            );
        }

        // 'Simple access code'
        this.state = {
            sections: fields.get('user', sections),
            hideFields: [...new Set(hideFields)],
        };
    }

    showDeleteAccount = () => {
        this.setState({ showDeleteAccount: true });
    }

    hideDeleteAccount = () => {
        this.setState({ showDeleteAccount: false });
    }

    deleteAccount = async () => {
        const { userStore } = this.props.stores;
        const { user } = userStore;
        const { id } = user;
        if (!confirm('Are you sure you want to delete your account?')) {
            return;
        }
        await userStore.save({
            id: user.id,
            delete: true,
            deleteDate: new Date(),
        });
        this.setState({ accountMarkedForDeletion: true });
    }

    componentDidMount() {
        const { userStore } = this.props.stores;
        const { user } = userStore;
        if (!user.firstname || !user.lastname) {
            this.setState({ showEdit: true });
        }
    }

    render() {
        const { sections, showDeleteAccount, accountMarkedForDeletion, hideFields, showEdit } = this.state;
        const { userStore, appState } = this.props.stores;
        const { opts, showEdit: showEditProp } = this.props;
        const { drawerLevel } = this.props;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const image = localUtil.getImage({ user });
        const name = localUtil.displayName(user, true);

        if (!showEdit && !showEditProp) {
            return (<></>);
        }

        return (<>
            <div class='w-100 d-flex flex-column justify-content-start'>
                <div class='d-flex flex-column justify-content-start h-100 pt-1'>
                    <FormFields
                        stores={this.props.stores}
                        sections={sections}
                        holdingStore={userStore}
                        objectName={'user'}
                        isNew={false}
                        drawerLevel={drawerLevel}
                        hideFields={hideFields}
                        hideAdmin={true}
                        skipSurroundingDivs={false}
                    />
                </div>
            </div>
        </>);
    }
}

export default UserProfileInline;
