import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class ConventionResultStore extends LocalModel {
    constructor() {
        super('conventionResult', {
            namePlural: 'conventionResults',
            namePluralReal: 'conventionResults',
            sort: '-createdDate',
            limit: 2000,
            api: {
                search: {
                    url: '/api/conventionresults/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/conventionresults/',
                    params: {},
                },
                save: {
                    url: '/api/conventionresults/',
                    params: {},
                },
                delete: {
                    url: '/api/conventionresults/',
                    params: {},
                },
            },
        });
    }

    @observable newConventionResult = {};

    @observable conventionResult = {};

    @observable conventionResults = [];

    @observable tags = [];
}

const store = new ConventionResultStore();
export default store;
