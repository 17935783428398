import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalAnimalListMini from '../../components/dyrejournal/listAnimalMini';
import DyrejournalPrescriptionListMini from '../../components/dyrejournal/listPrescriptionMini';
import Versions from '../../components/versions';

import ImageScroller from '../../components/imagescroller';

@observer
class ViewJournal extends Component {
	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('journal', 'Journal basics'),
            sectionsMedical: fields.get('journal', 'Journal medical'),
            sectionsClinical: fields.get('journal', 'Journal clinical'),
            sectionsPassport: fields.get('journal', 'Journal passport'),
            sectionsExtra: fields.get('journal', 'Journal extra'),
            sectionsMedia: fields.get('journal', 'Journal media'),
        };
        this.searchTimer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id } = props;
        const { journalStore } = this.props.stores;
        await this.loadJournal(id);
        await journalStore.loadVeterinaries();
    }

    loadJournal = async id => {
        const { journalStore, animalStore, visitorStore } = this.props.stores;
        await journalStore.load(id);
        const { journal } = journalStore;
        if (journal) {
            const { animal } = journal;
            await animalStore.load(animal);
            const { animal: currentAnimal } = animalStore;
            if (currentAnimal) {
                const { owner } = currentAnimal;
                await visitorStore.load(owner);
            }
            // await this.loadJournals([animal], journal.id);
        }
    }

    loadJournals = async (animalIds, idNotIn) => {
        const { journalStore, saleStore } = this.props.stores;
        const response = await journalStore.load({
            query: {
                animal: animalIds,
                idNotIn,
            },
            skipUpdate: true,
        });
        journalStore.updateKeyValue('journals', response);
    }

    onImageClick = e => {
        e.stopPropagation();
        e.preventDefault();
        const { image } = e.target.dataset;
        const {
            drawerLevel = 1,
        } = this.props;
        const { appState } = this.props.stores;

        appState.openDrawerRight('viewImage', {
            image,
            callback: async () => {
                // console.log('onClickEditJournal callback');
            },
        }, drawerLevel + 1);
    }

    onClickViewVisit = async e => {
        const { id } = e.target.closest('button').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState, saleStore } = this.props.stores;
        saleStore.createNewSale({});

        const newSale = await saleStore.load(parseInt(id, 10), false, { skipUpdate: true });
        if (id) {
            await this.loadSalePayments(parseInt(id, 10));
        }
        if (newSale[0].visitors) {
            const visitorIds = newSale[0].visitors.map(e => e.id);
            // console.log('onClickViewVisit', visitorIds)
            await this.loadAnimals(visitorIds);
            await this.loadSales(visitorIds);
        }

        if (newSale[0].animals) {
            const journalIds = newSale[0].journals.map(e => e.id);
            const animalIds = newSale[0].animals.map(e => e.id);
            await this.loadJournals(animalIds, newSale[0].id, journalIds);
        }
        saleStore.updateKeyValue('newSale', newSale[0]);

        appState.appContainerScrollTop();
        appState.toggleDrawerRight();
        appState.toggleDrawer();
    }

    onClickViewVisitor = () => {
        const { appState, saleStore, animalStore, visitorStore } = this.props.stores;
        const { visitor } = visitorStore;
        const visitors = [visitor];
        const { newSale } = saleStore;
        saleStore.createNewSale({});
        saleStore.updateObjectKeyValue('newSale', 'visitors', visitors);
        saleStore.updateObjectKeyValue('newSale', 'id', null);
        saleStore.updateObjectKeyValue('newSale', 'saleNumber', null);
        saleStore.updateObjectKeyValue('newSale', 'saleDate', null);
        saleStore.updateObjectKeyValue('newSale', 'animals', []);

        const visitorIds = visitors.map(v => v?.id);
        if (visitorIds.length > 0) {
            this.loadAnimals(visitorIds);
            this.loadSales(visitorIds);
        }
        animalStore.updateObjectKeyValue('newAnimal', 'owner', visitorIds[0]);

        const { isProductionAnimal } = newSale;
        const isProductionAnimals = util.isDefined(isProductionAnimal) ? isProductionAnimal : visitors.some(v => v?.producerNumber);
        saleStore.updateKeyValue('isProductionAnimals', isProductionAnimals);

        appState.appContainerScrollTop();
        appState.toggleDrawerRight();
        appState.toggleDrawer();
    }

    // onClickViewAnimal = async e => {
    //     const { id } = e.target.closest('i').dataset;
    //     const { animalStore } = this.props.stores;
    //     await animalStore.load(id);
    //     const { animal } = animalStore;



    loadAnimals = async visitorIds => {
        const { animalStore, saleStore, journalStore } = this.props.stores;
        const response = await animalStore.load({
            query: {
                owner: visitorIds,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('visitorAnimals', response);
        journalStore.updateKeyValue('animals', response);
    }

    loadSales = async visitorIds => {
        if (!util.isArray(visitorIds) || visitorIds.length === 0) {
            return false;
        }
        const { saleStore } = this.props.stores;
        const response = await saleStore.load({
            query: {
                visitor: visitorIds,
                today: 1,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('currentVisitorSales', response);
    }

    loadSalePayments = async saleId => {
        if (!util.isNumber(saleId)) {
            return false;
        }
        const { salePaymentStore, saleStore } = this.props.stores;
        const response = await salePaymentStore.load({
            query: {
                sale: saleId,
            },
            skipUpdate: true,
            limit: 5000,
        });
        saleStore.updateKeyValue('currentPayments', response);
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const {
            week,
            isNew,
            hideAdmin = true,
            drawerLevel = 1,
            showJournals = true,
            animals = [],
        } = this.props;
        const {
            sections,
            sectionsMedical,
            sectionsClinical,
            sectionsPassport,
            sectionsExtra,
            sectionsMedia,
        } = this.state;
        const { userStore, journalStore, animalStore, visitorStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { journal, journals, veterinaries, animals: journalAnimals } = journalStore;
        // const { animal } = animalStore;
        const animal = animals.find(a => a.id === journal.animal) || {};

        const { animal: currentAnimal } = animalStore;
        const { visitor } = visitorStore;

        const finalAnimals = animals.length > 0 ? animals : [currentAnimal];

        const { prescriptions } = journal;

        const veterinary = veterinaries.find(vet => vet.id === journal.veterinary);
        const veterinaryName = veterinary ? `${veterinary.firstname} ${veterinary.lastname}` : journal.veterinaryName;

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <Versions stores={this.props.stores} type='journal' id={journal.id} key={`journal-${journal.id}`} />

                    <div class='font-weight-lighter px-3 box-header'>
                        <Text id='dyrejournal.journal.view'>View Journal</Text>: {journal.id}
                    </div>

                    {/* <DyrejournalAnimalListMini
                        classNames='mx-4'
                        stores={this.props.stores}
                        animals={[animal]}
                        journals={journals}
                        saleIsLocked={true}
                        // saveSale={saveSale}
                        drawerLevel={drawerLevel}
                        showNewJournal={false}
                        showNewBooking={false}
                        showJournals={showJournals}
                    /> */}
                    <div class='font-weight-lighter text-muted mx-4 mt-5'>
                        <small class='text-uppercase ml-3'>
                            <Text id='dyrejournal.journal.animal'>Animal</Text>
                        </small>
                    </div>
                    <div class={`d-flex flex-column mx-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                        {finalAnimals.map(e => {
                            return (<>
                                <div class={`d-flex flex-row justify-content-between px-3 py-0 animal-line`}>
                                    <div class='flex-fill'>
                                        {/* <i class='fa-duotone fa-eye text-muted mr-2' data-id={e.id} onClick={this.onClickViewAnimal} /> */}
                                        <span class='font-weight-lighter'>{e.id}:</span> {e.name} ({e.species}/{e.breed})
                                    </div>
                                    <div class='ml-2'>
                                        {e.birth && util.age(e.birth)}
                                    </div>
                                </div>
                                {e.insuranceId && <>
                                    <div class={`d-flex flex-row justify-content-between px-3 py-0 animal-line`}>
                                        <div class='flex-fill'>
                                            {e.insuranceId} {e.insuranceCompany ? `(${e.insuranceCompany})` : ''}
                                        </div>
                                    </div>
                                </>}
                            </>);
                        })}
                    </div>
                    {visitor && visitor.id && <>
                        <div class='font-weight-lighter text-muted mx-4 mt-3'>
                            <small class='text-uppercase ml-3'>
                                <Text id='dyrejournal.journal.owner'>Owner</Text>
                            </small>
                        </div>
                        <div class={`d-flex flex-column mx-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>

                            <div class={`d-flex flex-row justify-content-between px-3 py-0 animal-line`}>
                                <div class='flex-fill'>
                                    <i class='fa-duotone fa-eye text-muted mr-2' onClick={this.onClickViewVisitor} />
                                    <span class='font-weight-lighter'>{visitor.id}:</span> {visitor.firstname} {visitor.lastname}
                                </div>
                                <div class='ml-2'>
                                    {visitor.cellphone}
                                </div>
                            </div>
                        </div>
                    </>}

                    {journal.sale > 0 && <>
                        <div class='font-weight-lighter text-muted mx-4 mt-4'>
                            <small class='text-uppercase ml-3'>
                                <Text id='dyrejournal.journal.sale'>Sale</Text>
                            </small>
                        </div>
                        <div class={`d-flex flex-column mx-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                            <div class={`d-flex flex-row justify-content-between px-3 py-0 animal-line`}>
                                <button
                                    class='btn btn-sm btn-outline-success rounded-pill'
                                    data-id={journal.sale}
                                    onClick={this.onClickViewVisit}
                                >
                                    <Text id='dyrejournal.goto-sale'>Go to sale</Text>: {journal.sale}
                                </button>
                            </div>
                        </div>
                    </>}

                    <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                        <ViewFields
                            stores={this.props.stores}
                            sections={sections}
                            fields={['date', 'id']}
                            holdingStore={journalStore}
                            objectName={isNew ? 'newJournal' : 'journal'}
                            isNew={isNew}
                            hideAdmin={hideAdmin}
                            drawerLevel={drawerLevel}

                            // filter={field => {
                            // }}
                        >

                            <div
                                class={`d-flex flex-row align-items-center position-relative pl-1 pr-3 w-100`}
                            >
                                <div
                                    class={`d-flex justify-content-center align-items-center text-muted`}
                                    style={`
                                        width: 44px;
                                        height: 44px;
                                        min-width: 44px;
                                        opacity: 0.3;
                                    `}
                                >
                                    <i class={`fa-solid fa-user-doctor`} />
                                </div>
                                <span class={`flex-grow-1 overflow-auto`}>
                                    <div class='p-3 position-relative'>
                                        <small class='text-muted position-absolute' style='top: 0px; left: 0px;'>
                                            <Text id='input.veterinary'>Veterinary</Text>
                                        </small>
                                        {veterinaryName}
                                    </div>
                                </span>
                            </div>


                        </ViewFields>


                    </div>

                    <div class='font-weight-lighter text-muted mx-4'>
                        <small class='text-uppercase ml-3'>
                            <Text id='dyrejournal.journal.prescriptions'>Journal prescriptions</Text>
                        </small>
                    </div>
                    <DyrejournalPrescriptionListMini
                        classNames='mx-4'
                        stores={this.props.stores}
                        prescriptions={prescriptions}
                        saleIsLocked={true}
                        saveJournal={this.saveJournal}
                        drawerLevel={drawerLevel}
                    />

                    <div class='bg-light d-flex flex-column justify-content-start mt-4'>
                        <ViewFields
                            stores={this.props.stores}
                            sections={sectionsClinical}
                            holdingStore={journalStore}
                            objectName={isNew ? 'newJournal' : 'journal'}
                            isNew={false}
                            hideAdmin={hideAdmin}
                            drawerLevel={drawerLevel}

                            // filter={field => {
                            // }}
                        />
                    </div>

                    <div class='bg-light d-flex flex-column justify-content-start mt-4'>
                        <ViewFields
                            stores={this.props.stores}
                            sections={sectionsMedical}
                            holdingStore={journalStore}
                            objectName={isNew ? 'newJournal' : 'journal'}
                            isNew={false}
                            hideAdmin={hideAdmin}
                            drawerLevel={drawerLevel}

                            // filter={field => {
                            // }}
                        />
                    </div>

                    <div class='bg-light d-flex flex-column justify-content-start mt-4'>
                        <ViewFields
                            stores={this.props.stores}
                            sections={sectionsPassport}
                            holdingStore={journalStore}
                            objectName={isNew ? 'newJournal' : 'journal'}
                            isNew={false}
                            hideAdmin={hideAdmin}
                            drawerLevel={drawerLevel}

                            // filter={field => {
                            // }}
                        />
                    </div>

                    {/* <div class='bg-light d-flex flex-column justify-content-start mt-4'>
                        <ViewFields
                            stores={this.props.stores}
                            sections={sectionsExtra}
                            holdingStore={journalStore}
                            objectName={isNew ? 'newJournal' : 'journal'}
                            isNew={false}
                            hideAdmin={hideAdmin}
                            drawerLevel={drawerLevel}

                            // filter={field => {
                            // }}
                        />
                    </div> */}

                    {journal.images && journal.images.length > 0 && <>
                        <div class='w-100 d-flex flex-column'>
                            <div class='px-0 box-header-info d-flex flex-column'>
                                <div class='d-flex'>
                                    {/* {JSON.stringify(part)} */}
                                    <ImageScroller
                                        images={journal.images}
                                        stores={this.props.stores}
                                        maxHeight={'60vh'}
                                        showImg={img => localUtil.displayImg(img, this.props, '1920x')}
                                        onImageClick={this.onImageClick}
                                    />
                                </div>
                            </div>
                        </div>
                    </>}

                    <div class='bg-light d-flex flex-column justify-content-start mt-4'>
                        <ViewFields
                            stores={this.props.stores}
                            sections={sectionsMedia}
                            holdingStore={journalStore}
                            objectName={isNew ? 'newJournal' : 'journal'}
                            isNew={false}
                            hideAdmin={hideAdmin}
                            drawerLevel={drawerLevel}

                            // filter={field => {
                            // }}
                        />
                    </div>


                    <div class='bg-light d-flex flex-column justify-content-start mt-4'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sections}
                            fields={['comment']}
                            holdingStore={journalStore}
                            // objectName={isNew ? 'newJournal' : 'journal'}
                            objectName={isNew ? 'newJournal' : 'journal'}
                            isNew={journal.id ? false : true}
                            hideAdmin={hideAdmin}
                            drawerLevel={drawerLevel}

                            // filter={field => {
                            // }}
                        />
                    </div>
                    <div class='bg-light d-flex flex-column justify-content-start mt-4'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sectionsMedia}
                            fields={['files', 'images', 'links', 'emails']}
                            holdingStore={journalStore}
                            // objectName={isNew ? 'newJournal' : 'journal'}
                            objectName={isNew ? 'newJournal' : 'journal'}
                            isNew={journal.id ? false : true}
                            hideAdmin={hideAdmin}
                            drawerLevel={drawerLevel}

                            // filter={field => {
                            // }}
                        />
                    </div>

                </div>
            </div>
        );
    }
}

export default ViewJournal;
