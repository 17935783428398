import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

import md5 from 'crypto-js/md5';

configure({ enforceActions: 'always' });

class ConventionStore extends LocalModel {
    constructor() {
        super('convention', {
            namePlural: 'conventions',
            namePluralReal: 'conventions',
            sort: '-startDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/conventions/',
                    params: {
                        limit: 15,
                        sort: '-startDate',
                    },
                },
                load: {
                    url: '/api/conventions/',
                    params: {},
                },
                save: {
                    url: '/api/conventions/',
                    params: {},
                },
                delete: {
                    url: '/api/conventions/',
                    params: {},
                },
            },
        });
    }

    @observable newConvention = {};

    @observable convention = {};

    @observable conventions = [];

    @observable tags = [];

    @observable jwtToken = null;

    @observable otp = '';

    async getJwt(convention) {
        const response = await util.fetchApi(`/api/conventions/jwt/${convention}`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.updateKeyValue('jwtToken', response.data.jwtToken);
                this.updateKeyValue('otp', response.data.otp);
                return response;
            default:
                return 'Error';
        }
    }

    async checkOtp(convention, otp) {
        const response = await util.fetchApi(`/api/conventions/otp/${convention}/${otp}`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                return response;
            default:
                return 'Error';
        }
    }

    async signUp(user, shootingClass) {
        const response = await util.fetchApi(`/api/conventions/signup/${this.convention.id}`, { method: 'PATCH' }, {
            id: this.convention.id,
            participants: {
                id: user.id,
                firstname: user.firstname,
                lastname: user.lastname,
                cellphone: user.cellphone,
                email: user.email,
                class: shootingClass,
                md5: md5(JSON.stringify(user)).toString(),
            },
        });
        switch (response.status) {
            case 202:
                return response;
            default:
                return 'Error';
        }
    }

    async signUpAsAdmin(user) {
        // console.log('signUp', user);
        await this.save({
            id: this.convention.id,
            participants: {
                id: user.id,
                firstname: user.firstname,
                lastname: user.lastname,
                cellphone: user.cellphone,
                email: user.email,
                md5: md5(JSON.stringify(user)).toString(),
            },
        });
    }

    async newRound(type) {
        if (!type) {
            return;
        }
        const participants = [...this.convention.participants];
        const stageNumber = this.convention.stages.length + 1;

        const newStage = {
            type,
            stages: type === 'norgesfelt' ? 8 : null,
            name: `Runde ${stageNumber}`,
            date: util.isoDate(),
        };
        newStage.md5 = md5(JSON.stringify(this.convention.stages)).toString();

        const response = await this.save({
            id: this.convention.id,
            // [`participants${stageNumber}`]: participants,
            stages: newStage,
        });

        return response;
    }

    async newParticipant(user = {}, stageMd5 = null) {
        if (!user.id) {
            return;
        }

        let stageName = 'participants';
        if (stageMd5) {
            const stageIdx = this.convention.stages.findIndex((s) => s.md5 === stageMd5);
            if (stageIdx) {
                stageName = `participants${stageIdx + 1}`;
            }
        }

        const response = await this.save({
            id: this.convention.id,
            [stageName]: {
                id: user.id,
                firstname: user.firstname,
                lastname: user.lastname,
                cellphone: user.cellphone,
                email: user.email,
                md5: md5(JSON.stringify(user)).toString(),
                date: util.isoDate(),
            },
        });
        return response;
    }
}

const store = new ConventionStore();
export default store;
