import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class ArticleStore extends LocalModel {
    constructor() {
        super('article', {
            namePlural: 'articles',
            namePluralReal: 'articles',
            sort: '-sort -createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/articles/',
                    params: {
                        limit: 100,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/articles/',
                    params: {},
                },
                save: {
                    url: '/api/articles/',
                    params: {},
                },
                delete: {
                    url: '/api/articles/',
                    params: {},
                },
            },
        });
    }

    @observable newArticle = {};

    @observable article = {};

    @observable articles = [];

    @observable googleSheet = {};

    async getGoogleSheet(sheetId) {
        const response = await util.fetchApi(`/api/googlesheets/${sheetId}`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.updateKeyValue('googleSheet', response.data);
        }
    }

}

const store = new ArticleStore();
export default store;
