import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class SaleReceipt extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { week, isNew, drawerLevel } = this.props;
        const { sections } = this.state;
        const { customerStore, userStore, saleStore } = this.props.stores;
        const { customer } = userStore;
        const { user, isAdmin } = userStore;

        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const {
            saleTotal = 0,
            saleTax = 0,
            newSale = {},
            currentPayments = [],
        } = saleStore;
        // const saleIsLocked = !!newSale.paymentMethod;
        // console.log({ newSale })

        const dueDate = new Date(newSale.dueDate || newSale.saleDate || new Date());
        if (customer.invoiceDueDays > 0 && !newSale.dueDate) {
            dueDate.setDate(dueDate.getDate() + customer.invoiceDueDays);
        }
        const { days: daysOverDue } = util.dateDiff(dueDate, new Date());


        const restPayment = saleStore.sumRestPayments(currentPayments, saleStore.sumTotal(newSale.products, false));

        const saleHasProductsWithGranularPrice = newSale.products && newSale.products.some(p => p.medicine > 0 || p.fee > 0 || p.equipment > 0 || p.other > 0);
        const saleHasProductsWithSaleDate = newSale.products && newSale.products.some(p => p.saleDate);
        const saleHasProductsWithDiscount = newSale.products && newSale.products.some(p => p.discount > 0);

        // console.log('newSale.dueDate', newSale.dueDate)
        // console.log('newSale.saleDate', newSale.saleDate)
        // console.log('dueDate', dueDate)

        return (<>
            <div class='receipt'>
                <div class='d-flex flex-column receipt'>
                    <div class='d-flex flex-column align-items-center'>

                        {newSale.status === 1001 ? <>
                            <h2><Text id='dyrejournal.priceoffer'>Price Offer</Text></h2>
                            <Text id='dyrejournal.priceoffer-help'>This is a price offer and should not be paid.</Text>
                            </> : <>
                            {newSale.paidDate ? <>
                                <h2><Text id='dyrejournal.receipt'>RECEIPT</Text></h2>
                            </> : <>
                                <h2>
                                    <Text id='dyrejournal.invoice'>INVOICE</Text>
                                    {daysOverDue > 7 ? <span class='ml-2'> - Purring!</span> : ''}
                                </h2>
                            </>}
                        </>}

                    </div>
                    <div class='d-flex flex-row justify-content-between'>
                        <div class='text-center d-flex align-items-center'>
                            {customer.images && customer.images.length > 0 && <img src={localUtil.displayImg(customer.images[0], this.props, '220x')} class='img-fluid' style='max-height: 100px;' />}
                        </div>
                        <div class='d-flex align-items-center'>
                            <h3 class='font-weight-lighter'>{customer.name}</h3>
                        </div>
                    </div>
                    <div class='d-flex flex-row justify-content-between mt-5'>
                        <div class='d-flex flex-column'>
                            <div class='d-flex flex-row'>
                                <div class='font-weight-light'>
                                    <Text id='dyrejournal.customer-name'>Customer</Text>:
                                </div>
                                <div class='d-flex flex-column ml-2'>
                                    {newSale.visitors && newSale.visitors.length > 0 && newSale.visitors.map((visitor, i) => {
                                        return (<>
                                            <div>
                                                {visitor.firstname} {visitor.lastname}
                                            </div>
                                            <div>
                                                {visitor.address}
                                            </div>
                                            <div>
                                                {visitor.postalcode} {visitor.place}
                                            </div>
                                        </>);
                                    })}

                                    {newSale.animals && newSale.animals.length > 0 && newSale.animals.map((animal, i) => {
                                        return (<>
                                            <div>
                                                {animal.name} ({animal.species}/{animal.breed})
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </div>
                            <div class='d-flex flex-row'>
                                <div class='font-weight-light'>
                                    <Text id='dyrejournal.customer-id'>Customer ID</Text>:
                                </div>
                                <div class='ml-2'>
                                    {newSale.visitor || newSale.visitors[0].id}
                                </div>
                            </div>
                        </div>
                        <div class='d-flex flex-column align-items-end'>
                            <div class='d-flex flex-row'><Text id='dyrejournal.date'>Date:</Text> {util.formatDate(newSale.saleDate || new Date(), { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}</div>
                            {newSale.status === 1001 ? <>
                                <div class='d-flex flex-row'><Text id='dyrejournal.invoice-id'>Invoice ID:</Text> n/a</div>
                            </> : <>
                                {newSale.paidDate ? <>
                                    <div class='d-flex flex-row'><Text id='dyrejournal.receipt-id'>Receipt ID:</Text> {newSale.saleNumber || 'n/a'}</div>
                                </> : <>
                                    <div class='d-flex flex-row'><Text id='dyrejournal.invoice-id'>Invoice ID:</Text> {newSale.saleNumber || 'n/a'}</div>
                                </>}
                            </>}
                        </div>
                    </div>

                    <hr />

                    <div class='d-flex flex-column mt-5'>
                        <div class=''>
                            <table class='table table-sm table-borderless'>
                                <thead>
                                    <tr>
                                        {saleHasProductsWithSaleDate && <>
                                            <th class='text-muted text-left'><nobr><Text id='dyrejournal.date'>Date</Text></nobr></th>
                                        </>}
                                        <th class='text-right'><Text id='dyrejournal.quantity'>Quantity</Text></th>
                                        <th><Text id='dyrejournal.item'>Item</Text></th>
                                        {saleHasProductsWithGranularPrice ? <>
                                            <th class='text-center'><nobr><Text id='dyrejournal.sale.medicine'>Medicine</Text></nobr></th>
                                            <th class='text-center'><nobr><Text id='dyrejournal.sale.fee'>Fee</Text></nobr></th>
                                            <th class='text-center'><nobr><Text id='dyrejournal.sale.equipment'>Equipment</Text></nobr></th>
                                            <th class='text-center'><nobr><Text id='dyrejournal.sale.other'>Other</Text></nobr></th>
                                        </> : <>
                                            <th class='text-right'><Text id='dyrejournal.price'>Price</Text></th>
                                        </>}
                                        {saleHasProductsWithDiscount && <th class='text-right'><Text id='dyrejournal.sale.discount-amount'>Discount</Text></th>}
                                        <th class='text-right'><Text id='dyrejournal.total'>Total</Text></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newSale.products && newSale.products.length > 0 && newSale.products.map((product, i) => {
                                        return (<>
                                            <tr>
                                                {saleHasProductsWithSaleDate && <>
                                                    <td>
                                                        {product.saleDate && <>
                                                            {util.formatDate(product.saleDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                                        </>}
                                                    </td>
                                                </>}
                                                <td class='text-right'>{product.qty}</td>
                                                <td>
                                                    {product.animal ? <>
                                                        <span class='font-weight-light text-muted mr-2'>{product.animal.name}:</span>
                                                    </> : <></>}
                                                    {product.name}
                                                    {product.comment && <>
                                                        <br /><small class='text-muted'>({product.comment})</small>
                                                    </>}
                                                </td>
                                                {product.medicine > 0 || product.fee > 0 || product.equipment > 0 || product.other > 0 ? <>
                                                    <td class='text-right'><nobr>{util.format(product.medicine || 0, 2, ',', ' ')}</nobr></td>
                                                    <td class='text-right'><nobr>{util.format(product.fee || 0, 2, ',', ' ')}</nobr></td>
                                                    <td class='text-right'><nobr>{util.format(product.equipment || 0, 2, ',', ' ')}</nobr></td>
                                                    <td class='text-right'><nobr>{util.format(product.other || 0, 2, ',', ' ')}</nobr></td>
                                                </> : <>
                                                    <td class='text-right' colspan={saleHasProductsWithGranularPrice ? 4 : 1}><nobr>{util.format(product.price, 2)}</nobr></td>
                                                </>}
                                                {saleHasProductsWithDiscount && <>
                                                    <th class='text-right'>
                                                        <nobr>{product.discountAmount ? `${util.format(product.discountAmount, 2, ',', ' ')}` : ''}</nobr>
                                                    </th>
                                                </>}
                                                <td class='text-right'><nobr>{saleStore.lineTotal(product)}</nobr></td>
                                            </tr>
                                        </>);
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class='d-flex flex-row justify-content-end'>
                        <div class='d-flex flex-column mt-5 w-50 justify-content-end'>
                            <table class='table table-sm table-borderless'>
                                <tbody>
                                    <tr>
                                        <td class='text-right'><Text id='dyrejournal.total'>Total</Text></td>
                                        <td class='text-right'>{saleStore.sumTotal(newSale.products)}</td>
                                    </tr>
                                    <tr>
                                        <td class='text-right'><Text id='dyrejournal.of-which-vat'>Of which VAT</Text></td>
                                        <td class='text-right'>{saleStore.sumVat(newSale.products)}</td>
                                    </tr>
                                    {newSale.paidDate && <>
                                        <tr>
                                            <td class='text-right'><Text id='dyrejournal.paid-with'>Paid with</Text></td>
                                            <td class='text-right'><i class={saleStore.getPaymentMethodIcon(newSale.paymentMethod)} /> {newSale.paymentMethod}</td>
                                        </tr>
                                        <tr>
                                            <td class='text-right'><Text id='dyrejournal.paid-date'>Paid date</Text></td>
                                            <td class='text-right'>{util.formatDate(newSale.paidDate, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}</td>
                                        </tr>
                                    </>}

                                    {currentPayments && currentPayments.length > 1 && <>
                                        <tr>
                                            <td class='text-right'><Text id='dyrejournal.partial-payment-total'>Partial payment total</Text></td>
                                            <td class='text-right'>{saleStore.sumTotalPayments(currentPayments)}</td>
                                        </tr>
                                    </>}
                                    {restPayment > 0 && <>
                                        <tr>
                                            <td class='text-right'><Text id='dyrejournal.partial-payment-rest-payment'>Rest payment</Text></td>
                                            <td class='text-right'>{saleStore.sumRestPayments(currentPayments, saleStore.sumTotal(newSale.products, true))}</td>
                                        </tr>
                                    </>}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {currentPayments && currentPayments.length > 0 && <>
                        <div class='d-flex flex-column mt-5'>
                            <div class=''>
                                <span class='font-weight-bolder'><Text id='dyrejournal.payments'>Payments</Text></span>
                                <div class={`table-responsive`}>
                                    <table class='table table-sm table-borderless'>
                                        <thead>
                                            <tr>
                                                <th class='text-muted text-left'><nobr><Text id='dyrejournal.partial-payment-date'>Date</Text></nobr></th>
                                                <th class='text-muted text-center'><nobr><Text id='dyrejournal.partial-payment-method'>Method</Text></nobr></th>
                                                <th class='text-muted text-right'><nobr><Text id='dyrejournal.sale.amount'>Amount</Text></nobr></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentPayments.map((e, idx) => <>
                                                <tr>
                                                    <td>{util.formatDate(e.paidDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}</td>
                                                    <td class='text-center'><i class={saleStore.getPaymentMethodIcon(e.paymentMethod)} /> {e.paymentMethod}</td>
                                                    <td class='text-right'>{util.format(e.amount, 2, ',', ' ')}</td>
                                                </tr>
                                            </>)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>}

                    {!newSale.paidDate && <>
                        <hr class='mt-2' />
                        {customer.invoiceInfo ? <>
                            <p style="margin: 0;"><b>{customer.invoiceInfo}</b></p>
                        </> : <></>}
                        <table width="100%" cellpadding="0" cellspacing="0">
                            <tbody>
                                {daysOverDue > 7 ? <>
                                    <tr>
                                        <td colspan='2'>
                                            <span class='font-weight-bold mr-2'>Purring!</span>
                                            <Text id='dyrejournal.invoice-overdue' fields={{ days: daysOverDue }}>Invoice is overdue by {daysOverDue} days.</Text>
                                        </td>
                                    </tr>
                                </> : <></>}
                                <tr>
                                    <td width="120" style="color: #808080; font-weight: 100;">
                                        <Text id='dyrejournal.due-date'>Due date</Text>:
                                    </td>
                                    <td>
                                        {util.formatDate(dueDate)}
                                    </td>
                                </tr>
                                {currentPayments && currentPayments.length > 0 ? <>
                                    <tr>
                                        <td width="120" style="color: #808080; font-weight: 100;">
                                            <Text id='dyrejournal.amount'>Amount</Text>:
                                        </td>
                                        <td>
                                            {saleStore.sumRestPayments(currentPayments, saleStore.sumTotal(newSale.products, false))}
                                        </td>
                                    </tr>
                                </> : <>
                                    <tr>
                                        <td width="120" style="color: #808080; font-weight: 400; font-size: 1.5em;">
                                            <Text id='dyrejournal.amount'>Amount</Text>:
                                        </td>
                                        <td style='font-weight: 700; font-size: 1.5em;'>
                                            {saleStore.sumTotal(newSale.products)}
                                        </td>
                                    </tr>
                                </>}
                                <tr>
                                    <td width="120" style="color: #808080; font-weight: 100;">
                                        <Text id='dyrejournal.paid-to'>Paid to</Text>:
                                    </td>
                                    <td>
                                        {customer.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="120" style="color: #808080; font-weight: 100;">
                                        <Text id='dyrejournal.account-number'>Account number</Text>:
                                    </td>
                                    <td>
                                        {customer.invoiceAccount}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="120" style="color: #808080; font-weight: 100;">
                                        <Text id='dyrejournal.mark-payment-with'>Mark payment with</Text>:
                                    </td>
                                    <td>
                                        {newSale.status === 1001 ? <>
                                            <Text id='dyrejournal.invoice-id'>Invoice ID:</Text>: n/a
                                        </> : <>
                                            <Text id='dyrejournal.invoice-id'>Invoice ID:</Text>: {newSale.saleNumber || 'n/a'}
                                        </>}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </>}

                    <div class='d-flex flex-row justify-content-between mt-5'>
                        <div class='d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.business-register'>The Business Register</Text></div>
                                <div>{customer.orgNumber}</div>
                                <div>{customer.name}</div>
                                {customer.orgNumber2 && <div>{customer.orgNumber}</div>}
                                {customer.name2 && <div>{customer.name}</div>}
                            </small>
                        </div>
                        <div class='d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.address'>Address</Text></div>
                                <div>{customer.address}</div>
                                <div>{customer.postalcode} {customer.place}</div>
                            </small>
                        </div>
                        <div class='d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.phone-fax'>Phone/Fax</Text></div>
                                <div>{customer.phone}</div>
                                <div>{customer.fax}</div>
                            </small>
                        </div>
                        <div class='d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.internett'>Internet</Text></div>
                                <div>{customer.url}</div>
                                <div>{customer.email}</div>
                            </small>
                        </div>
                    </div>

                </div>

            </div>
        </>);
    }
}

export default SaleReceipt;
